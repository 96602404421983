import React from "react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteAdminApi, getAllAdmin } from "../../../services/customAPI";
import ReactPaginate from "react-paginate";
import { createColumnHelper } from "@tanstack/react-table";
import ButtonEdit from "../../../assets/svg/ButtonEdit.svg";
import DeleteIcon from "../../../assets/svg/deleteIcon.svg";
import dummyProfile from "../../../assets/svg/dummyProfile.svg";
import "./adminlist.css";
import Loader from "components/loader/loader";
import "react-toastify/dist/ReactToastify.css";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button, ChakraProvider } from "@chakra-ui/react";
import Navbar from "components/navbar";
import { getS3SignUrlApi } from "../../../services/customAPI";
import { toaster } from "components/common/toaster";
import Header from "components/common/TableHeader";
import Table from "components/common/TableBody";
// import LimitSelect from "components/common/paginationLimitSelect";

type RowObj = {
  name: customFieldType1;
  lastName: string;
  email: string;
  role: string;
  _id: string;
  action: customFieldType2;
};

type customFieldType1 = {
  name: string;
  profileImageKey?: string;
};

type customFieldType2 = {
  id: string;
  profileImageKey?: string;
};

const columnHelper = createColumnHelper<RowObj>();

const ImageWithFallback: React.FC<{
  src: string;
  alt: string;
  fallbackSrc: string;
  className?: any;
}> = ({ src, alt, fallbackSrc, className }) => {
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement>) => {
    // This function is called when the original image fails to load.
    // Set the source (src) of the image to the fallback source.
    event.currentTarget.src = fallbackSrc;
  };
  return (
    <img
      src={src}
      alt={alt}
      onError={handleImageError}
      className={className}
      style={{
        objectFit: "fill",
        height: "40px",
        borderRadius: "4px",
      }}
    />
  );
};

const Admins: React.FC = (props: any) => {
  const { sideBarUseState, setSideBarUseState } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const currentPage = useRef<number>(1);
  const navigate = useNavigate();
  const [values, setValues] = useState([]);
  // const [limit, setLimit] = useState<number>(10);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalState, setModalState] = useState(true);
  // const [pageCount, setPageCount] = useState<number>(1);
  // const [searchText, setSearchText] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [adminData, setAdminData] = useState([]);
  const [pageItemStartNumber, setPageItemStartNumber] = useState<number>(0);
  const [pageItemEndNumber, setPageItemEndNumber] = useState<number>(0);
  const firstRender = useRef(true);
  const [state, setState] = useState({
    pageCount: 1,
    searchText: "",
    adminData: [],
    limit: 10,
    noData: false,
  });

  const handleEditClick = (Id: string) => {
    // Redirect to the VehicleForm page with the vehicle ID as a parameter
    navigate(`../AdminForm/${Id}`);
  };

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Name</p>
      ),
      cell: (info: any) => (
        <p
          className="text-sm font-bold text-navy-700"
          style={{ display: "flex", alignItems: "center" }}
        >
          {!info.row.original.profileImageKey ? (
            <div
              style={{
                width: "40px",
                height: "40px",
                padding: "1px",
                borderRadius: "4px",
                display: "inline-block",
                marginRight: "6px",
                backgroundColor: "#F4F7FE",
              }}
            >
              <span
                style={{
                  position: "relative",
                  left: "14px",
                  top: "8.5px",
                  fontSize: "18px",
                }}
              >
                {info.getValue()?.slice(0, 1).toUpperCase()}
              </span>
            </div>
          ) : (
            <div
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "4px",
                display: "inline-block",
                marginRight: "6px",
                alignItems: "center",
              }}
            >
              <ImageWithFallback
                src={info.row.original.profileImageKey}
                alt={"profile image"}
                fallbackSrc={dummyProfile}
              />
            </div>
          )}
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
          {/* <span className="dark:text-white">{info.getValue()?.name}</span> */}
        </p>
      ),
    }),
    columnHelper.accessor("lastName", {
      id: "lastName",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Last Name
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Email</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("role", {
      id: "role",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Role</p>
      ),
      cell: (info) => (
        // <div className="flex items-center">
        //   {getStatusImage(info.row.original.status)}
        //   <p className="ml-2 text-sm font-bold text-navy-700 dark:text-white">
        //     {info.getValue()}
        //   </p>
        // </div>
        <div className="flex items-center">
          {/* {info.getValue() === "available" ? (
            <img src={completedRide} height={25} width={25} alt="Available" />
          ) : (
            <img src={cancelRide} height={25} width={25} alt="Unavailable" />
          )} */}
          <p
            className="
              text-sm font-bold text-navy-700 "
          >
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("action", {
      id: "action",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Actions
        </p>
      ),
      cell: (info) => (
        // <p className="text-sm font-bold text-navy-700 dark:text-white">
        //   <img src={eyeview} height={30} width={30} />
        // </p>
        <div className="flex items-center">
          {/* <img
            src={ButtonEdit}
            className="button-edit me-2"
            onClick={() => {
              //console.log("Edit", info.row.original._id);
              handleEditClick(info.row.original._id);
            }}
            height={30}
            width={30}
          /> */}
          <img
            src={DeleteIcon}
            className="button-delete"
            onClick={() => handleClickForDeleteModal(info.row.original)}
            height={30}
            width={30}
          />
        </div>
      ),
    }),
  ]; // eslint-disable-next-line

  const setPageItemRange = (
    currPageNumber: number,
    maxItemRange: number
  ): void => {
    let startNumber = currPageNumber * state.limit - state.limit + 1;
    if (startNumber < 0) {
      startNumber = 0;
    }
    setPageItemStartNumber(startNumber);

    const endNumber = currPageNumber * state.limit;
    setPageItemEndNumber(Math.min(endNumber, maxItemRange));
  };

  const deleteHandle = async (data: any) => {
    setIsLoading(true);
    onClose();
    //console.log("data", data);
    try {
      // setVisibleModal(false);
      const response: any = await deleteAdminApi(data);
      // //console.log("Response", response);
      if (response?.message) {
        toaster.successToast("Admin deleted successfully");
        if (state.adminData.length % state.limit === 1) {
          currentPage.current = currentPage.current - 1;
        }
        getPaginatedAdminData(state.limit);
      } else {
        toaster.errorToast("Something went wrong");
      }
      setIsLoading(false);
    } catch (error: any) {
      toaster.errorToast(error.response.data.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  // const searchVehicles = async () => {
  //   try {
  //     setIsLoading(true);
  //     const response: any = await searchVehiclesApi({
  //       page: currentPage.current,
  //       limit: limit,
  //       query: searchText.trim(),
  //     });

  //     if (!response.result) {
  //       errorToast("Vehicles not found");
  //     }
  //     setNoData(true);
  //     return response;
  //   } catch (error: any) {
  //     //console.log(error.response.data.success);
  //     setVehicleData([]);
  //     setPageCount(1);
  //     setNoData(error.response.data.success);
  //     setIsLoading(false);
  //   }
  // };

  // const searchVehicleFunction = async () => {
  //   const response: any = await searchVehicles();
  //   //console.log(response);
  //   // const data = response.result
  //   if (!response) {
  //     return;
  //   }
  //   setPageCount(response.pageCount);
  //   setVehicleData(await convertToUsableDriverArray(response.result));
  //   // setValues(data.map(extractSpecificValues))
  //   setPageItemRange(currentPage.current, response.totalDriver);
  //   setIsLoading(false);
  // };

  const handleSearchSubmit = async (e: any) => {
    // e.preventDefault();
    if (state.searchText.trim() == "") {
      return;
    }
    currentPage.current = 1;
    getPaginatedAdminData(state.limit);
  };

  function handlePageClick(e: any) {
    setIsLoading(true);
    currentPage.current = e.selected + 1;
    // if (searchText !== "") {
    //   searchVehicleFunction();
    // } else {
    getPaginatedAdminData(state.limit);
    // }
    setIsLoading(false);
  }

  async function getS3SignUrl(key: string, contentType: string, type: string) {
    const headers = { "Content-Type": "application/json" };
    const response: any = await getS3SignUrlApi(
      {
        key,
        contentType,
        type,
      },
      { headers }
    );
    return response?.url;
  }

  // async function convertToUsableDriverArray(vehicleArray: Array<any>) {
  //   const res = Promise.all(
  //     vehicleArray.map(async (vehicle) => {
  //       let path;
  //       if (vehicle?.profileImageKey) {
  //         path = await getS3SignUrl(
  //           vehicle.profileImageKey,
  //           "image/png",
  //           "get"
  //         );
  //       } else {
  //         path = "";
  //       }

  //       return {
  //         vehicleName: {
  //           name: vehicle.vehicleName,
  //           path: path,
  //         },
  //         vehicleNumber: vehicle.vehicleNumber,
  //         vehicleType: vehicle.vehicleType,
  //         vehicleStatus: vehicle.vehicleStatus,
  //         action: {
  //           id: vehicle._id,
  //           documentsKey: vehicle?.documentsKey,
  //           profileImageKey: vehicle?.profileImageKey,
  //         },
  //       };
  //     })
  //   );

  //   return res;
  // }

  const getPaginatedAdminData = async (limit: number) => {
    setIsLoading(true);
    try {
      const response: any = await getAllAdmin({
        page: currentPage.current,
        limit: limit,
        text: state.searchText.trim(),
      });
      //console.log("Admin Response", response);

      // if (!response.result) {
      //   toaster.errorToast("Admin data not found");
      // }

      if (response === 0) {
        console.log("Response", response);
        setState((prev: any) => ({
          ...prev,
          noData: true,
          pageCount: 0,
        }));
        setPageItemRange(0, 0);
      } else {
        setState((prev: any) => ({
          ...prev,
          pageCount: response.pageCount,
          searchText: state.searchText,
          adminData: response?.result[0]?.admin,
          noData: false,
        }));

        // setPageCount(response.pageCount);
        // setAdminData(response.result[0].admin);
        // setSearchText(searchText);
        setPageItemRange(currentPage.current, response.totalUsers);
        setIsLoading(false);
      }
    } catch (error: any) {
      toaster.errorToast(error.response.data.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaginationLimit = (e: any) => {
    setState((prevState) => ({
      ...prevState,
      limit: e.value,
    }));
    currentPage.current = 1;
    getPaginatedAdminData(e.value);
    // console.log("Limit", limit);
  };

  const handleClickForDeleteModal = (data: any) => {
    setIsLoading(true);
    // setVisibleModal(true);
    setModalState(true);
    setSelectedItem(data);
    //console.log("Data", data);
    onOpen();
    setIsLoading(false);
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      getPaginatedAdminData(state.limit);
    } else {
      if (state.searchText == "") {
        currentPage.current = 1;
        getPaginatedAdminData(state.limit);
      }
    }
  }, [state.searchText, state.limit]);

  // useEffect(() => {
  //   currentPage.current = 1;
  //   getPaginatedAdminData();
  // }, []);

  return (
    <div>
      <Navbar
        flag={true}
        brandText="Vehicles"
        sideBarUseState={sideBarUseState}
        setSideBarUseState={setSideBarUseState}
        // handleSearch={(e: React.SyntheticEvent<EventTarget>) =>
        //   handleSearchSubmit(e)
        // }
        // setSearchText={setSearchText}
      />
      {/* {!noData ? (
        <Card extra={" m-4 w-full p-4 h-full"}>
          <div className="m-4 text-center">
            <h2 style={{ fontSize: "30px" }}>No Results !</h2>
          </div>
        </Card>
      ) : ( */}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <>
            <div className="mt-4">
              <Header
                title="Admin"
                showSelect={false}
                setSearchText={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    searchText: value,
                  }))
                }
                handleSearchSubmit={handleSearchSubmit}
                searchText={state.searchText}
                navigation="../admins/AdminForm"
              />
              <Table
                tableData={state.adminData}
                columns={columns}
                noData={state.noData}
              />
              <div
                className="mx-2 "
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color: "#5E6278",
                }}
              >
                <h5>
                  {pageItemStartNumber} - {pageItemEndNumber}
                </h5>
                {/* <LimitSelect
                  limit={state.limit}
                  handlePaginationLimit={handlePaginationLimit}
                /> */}
                {/* <Select
                  isSearchable={false}
                  // className="w-400"
                  className="custom-select"
                  options={paginationLimit}
                  onChange={(e: any) => {
                    handlePaginationLimit(e);
                  }}
                  value={paginationLimit.filter(function (option: any) {
                    return option.value === state.limit;
                  })}
                  name="limit"
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided: any) => ({
                      ...provided,
                      zIndex: 9999,
                      position: "absolute",
                    }),
                    option: (provided: any, state: any) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#f2f3f7" : "white", // Change the background color here
                      color: "black", // Change the text color here
                      "&:hover": {
                        backgroundColor: "#f2f3f7", // Change the background color on hover
                      },
                    }),
                    // control: (base: any) => ({
                    //   ...base,
                    //   flexDirection: 'row-reverse',
                    // })
                  }}
                  components={{
                    DropdownIndicator: arrowdown,
                    IndicatorSeparator: () => null,
                    ValueContainer: filter,
                  }}
                /> */}
                <div style={{ marginTop: "1rem" }}>
                  <ReactPaginate
                    breakLabel=" .  .  . "
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={state.pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName="pagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="previous-page-btn"
                    previousLinkClassName="page-link"
                    nextClassName="next-page-btn"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    forcePage={currentPage.current - 1}
                  />
                </div>
              </div>
            </div>
          </>
          {/* )} */}
          {isOpen && (
            <ChakraProvider>
              <Modal
                isCentered={true}
                isOpen={isOpen}
                onClose={onClose}
                size="xs"
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader></ModalHeader>
                  {/* <ModalCloseButton /> */}
                  <div className="mb-2 flex justify-center">
                    {/* <img src={deleteIcon} /> */}
                  </div>
                  <ModalBody className="text-center">
                    Are you sure you want to Delete? <br />
                    {'"' + selectedItem.name + '"'}
                  </ModalBody>
                  <div className="mt-3 flex justify-center">
                    <Button
                      // style={{backgroundColor: 'red'}}
                      className="cancel-delete-modal-button mx-2"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="delete-modal-button mx-2"
                      onClick={() => deleteHandle(selectedItem._id)}
                    >
                      Delete
                    </Button>
                  </div>
                  <ModalFooter></ModalFooter>
                </ModalContent>
              </Modal>
            </ChakraProvider>
          )}
        </>
      )}
    </div>
  );
};

export default Admins;
