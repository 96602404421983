import React from "react";
import "../videoForm/videoForm.css";
import profilePlaceholder from "../../../../assets/svg/profilePlaceholder.svg";
import uploadCloud from "../../../../assets/svg/upload-cloud.svg";
import { ErrorMessage } from "formik";

export const VideoInput = (props: any) => {
  const {
    videoPreview,
    videoRef,
    params,
    setFieldValue,
    setFinalVideo,
    setVideoPreview,
    handleBlur,
    nameVideo,
    values,
    errors,
    touched,
    setVideoDuration,
    setIsValidation,
  } = props;

  const handleVideoLoadedMetadata = () => {
    setVideoDuration((prev: any) => ({
      ...prev,
      [nameVideo]: videoRef.current.duration,
    }));
  };

  // Use a regular expression to split the string at underscores
  const words = nameVideo.split("_");
  // Capitalize the first letter of the first word
  const firstWord = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  // Join the modified first word with the rest of the words using spaces
  const transformedName = words.slice(1).join(" ") + "p" + " " + firstWord;

  return (
    <>
      <div>
        {videoPreview && (
          <>
            <p className="mb-2 text-sm font-bold text-navy-700 dark:text-white">
              {transformedName}
            </p>
            <div
              className="image-preview mr-4 w-full sm:w-[300px] h-fit"
              style={{
                // width: "300px",
                // height: "200px",
                padding: "2px",
                border: "2px solid #9CA3AF",
                borderRadius: "4px",
              }}
            >
              <video
                ref={videoRef}
                src={videoPreview}
                onLoadedMetadata={handleVideoLoadedMetadata}
                style={{
                  objectFit: "contain",
                  height: "100%",
                  width: "auto",
                  cursor: "pointer",
                  // padding: "5px",
                }}
                controls
              ></video>
            </div>
          </>
        )}
      </div>
      <div className="mt-4">
        <label>
          <div
            style={{
              border: "1px solid #9CA3AF",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            className="mt-2 h-20 rounded-xl border bg-white/0 p-3 text-sm outline-none"
          >
            <div
              className="flex items-center justify-center"
              style={{ cursor: "pointer" }}
            >
              <div>
                <div
                  className="mr-4 mt-3 flex items-center justify-center"
                  style={{ cursor: "pointer" }}
                >
                  <div className="mb-3">
                    <img
                      src={uploadCloud}
                      alt="Upload Cloud"
                      height="24px"
                      width="24px"
                      className="mr-2"
                    />
                  </div>
                  <div className="mb-3 text-center">
                    {!params.id ? (
                      <>
                        <a style={{ color: "#FF9966" }}>Click here {""}</a>
                        to upload {transformedName}
                      </>
                    ) : (
                      <>
                        <a style={{ color: "#FF9966" }}>Click here {""}</a>
                        to change {transformedName}
                      </>
                    )}
                  </div>
                </div>
                <input
                  // required
                  accept="video/*"
                  style={{
                    backgroundColor: "rgba(242, 242, 242, 0.5)",
                    display: "none",
                  }}
                  className="mt-2 h-12 w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                  name={nameVideo}
                  type="file"
                  id={nameVideo}
                  onChange={(event) => {
                    setFieldValue(`${nameVideo}`, event.target.files[0]);
                    const file = event.target.files[0];
                    setFinalVideo((prev: any) => ({
                      ...prev,
                      [nameVideo]: {
                        key: "",
                        url: "",
                        file: file,
                      },
                    }));
                    if (file) {
                      const reader = new FileReader();
                      reader.onload = (e) => {
                        setVideoPreview((prev: any) => ({
                          ...prev,
                          [nameVideo]: e.target.result,
                        }));
                      };
                      reader.readAsDataURL(file);
                    } else {
                      setVideoPreview((prev: any) => ({
                        ...prev,
                        [nameVideo]: null,
                      }));
                    }
                    if (event.target.files[0]) {
                      setIsValidation((prev: any) => ({
                        ...prev,
                        [nameVideo]: true,
                      }));
                    }
                  }}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </div>
        </label>
        <ErrorMessage
          name={nameVideo}
          component="div"
          className="error-input"
        />
      </div>
    </>
  );
};
