import React, { useRef, useState } from "react";
import Card from "../../../../components/card";
import Select from "react-select";
import { Button } from "@chakra-ui/react";
import "./videoForm.css";
import { ErrorMessage, Formik, useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import Loader from "components/loader/loader";
import Navbar from "../../../../components/navbar";
import axios from "axios";
import uploadCloud from "../../../../assets/svg/upload-cloud.svg";
import {
  getAuthors,
  handleUpdateVideoApi,
  deleteObjectFromS3Api,
  getCategoryByIdApi,
  getVideoDetailsById,
  getS3SignUrlApi,
  createVideoApi,
  getAllSkillsApi,
  getAllCategoryByTagApi,
} from "services/customAPI";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { VideoInput } from "../components/VideoInput";
import { v1 as uuid1 } from "uuid";
import { toaster } from "components/common/toaster";

type formvalues = {
  video_1080: any;
  video_720: any;
  video_480: any;
  thumbnail: any;
  courseTag: string;
  videoName: string;
  category: string;
  subCategory: string;
  skills: any;
  level: string;
  author: string;
  dynamicFields: any;
};

const VideoForm = (props: any) => {
  const { sideBarUseState, setSideBarUseState } = props;
  const firstRender = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState<formvalues>({
    video_1080: {},
    video_720: {},
    video_480: {},
    thumbnail: {},
    videoName: "",
    courseTag: "",
    category: "",
    subCategory: "",
    skills: [],
    level: "",
    author: "",
    dynamicFields: [{ duration: "", chapter: "" }],
  });
  const [videoDuration, setVideoDuration] = useState({
    video_1080: null,
    video_720: null,
    video_480: null,
  });
  const [courseId, setCourseId] = useState(null);
  const [classTypeState, setClassTypeState] = useState("");
  const [subCategoryState, setSubCategoryState] = useState("");
  const [levelState, setLevelState] = useState("");
  const [categoryState, setCategoryState] = useState("");
  const [authorState, setAuthorState] = useState("");
  const [authorName, setAuthorName] = useState("");
  const videoRef_1080 = useRef(null);
  const videoRef_720 = useRef(null);
  const videoRef_480 = useRef(null);
  const params = useParams();
  const [videoPreview, setVideoPreview] = useState({
    video_1080: null,
    video_720: null,
    video_480: null,
  });
  const [thumbnailPreview, setThumbnailPreview] = useState(null);
  const [initialVideo, setInitialVideo] = useState<any>({
    video_1080: null,
    video_720: null,
    video_480: null,
  });
  const [finalVideo, setFinalVideo] = useState<any>({
    video_1080: null,
    video_720: null,
    video_480: null,
  });
  const [finalThumbnailImage, setFinalThumbnailImage] = useState<any>();
  const [initialThumbnailImage, setInitialThumbnailImage] = useState<any>();
  const [isValidation, setIsValidation] = useState({
    video_1080: !params,
    video_720: !params,
    video_480: !params,
    thumbnail: !params,
  });
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [skillsOptions, setSkillsOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  const [authorsOptions, setAuthorsOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  const classType = [
    { value: "exclusive", label: "Exclusive" },
    { value: "editor_choice", label: "Editor Choice" },
    { value: "free", label: "Free" },
    { value: "promo", label: "Promo" },
  ];

  const categoryTag = [
    { value: "courses", label: "Courses" },
    { value: "upskill", label: "Upskill" },
    { value: "careers", label: "Careers" },
  ];

  const Level = [
    { value: "Beginner", label: "Beginner" },
    { value: "Intermediate", label: "Intermediate" },
    { value: "Advanced", label: "Advanced" },
  ];
  const toolbar = {
    options: ["inline", "blockType", "fontSize", "list", "history"],
  };

  const FILE_SIZE = 1024 * 1024;
  const SUPPORTED_FORMATS_video = ["video/mp4"];
  const SUPPORTED_FORMATS_image = ["image/jpg", "image/jpeg", "image/png"];

  const videoFormSchema = Yup.object().shape({
    video_1080: isValidation.video_1080
      ? Yup.mixed()
        .required("A file is required")
        .test(
          "fileFormat",
          "Unsupported Format",
          (value: any) =>
            value && SUPPORTED_FORMATS_video.includes(value.type)
        )
      : Yup.mixed(),
    video_720: isValidation.video_720
      ? Yup.mixed()
        .required("A file is required")
        .test(
          "fileFormat",
          "Unsupported Format",
          (value: any) =>
            value && SUPPORTED_FORMATS_video.includes(value.type)
        )
      : Yup.mixed(),
    video_480: isValidation.video_480
      ? Yup.mixed()
        .required("A file is required")
        .test(
          "fileFormat",
          "Unsupported Format",
          (value: any) =>
            value && SUPPORTED_FORMATS_video.includes(value.type)
        )
      : Yup.mixed(),
    thumbnail: isValidation.thumbnail
      ? Yup.mixed()
        .required("A file is required")
        .test("fileSize", "File too large", (value: any) => {
          return value && value.size <= FILE_SIZE;
        })
        .test(
          "fileFormat",
          "Unsupported Format",
          (value: any) =>
            value && SUPPORTED_FORMATS_image.includes(value.type)
        )
      : Yup.mixed(),
    videoName: Yup.string()
      .min(2, "Video name must be atleast two characters.")
      .required("Video name is required."),
    courseTag: Yup.string().required("Course tag is required."),
    category: Yup.string().required("Category is required."),
    subCategory: Yup.string().required("subCategory is required."),
    skills: Yup.array().min(1, "Please select at least one option"),
    level: Yup.string().required("level is required."),
    author: Yup.string().required("Author is required."),
    dynamicFields: Yup.array().of(
      Yup.object().shape({
        duration: Yup.number().required("Duration is required"),
        chapter: Yup.string().required("Chapter is required"),
      })
    ),
  });


  async function getS3SignUrl(key: string, contentType: string, type: string) {
    const headers = { "Content-Type": "application/json" };
    const response: any = await getS3SignUrlApi(
      {
        key,
        contentType,
        type,
      },
      { headers }
    );
    return response;
  }

  async function pushToS3(presignedUrl: string, upload: any) {
    const response = await axios.put(presignedUrl, upload);
    return response;
  }

  function convertStoMs(seconds: any) {
    let sec = Math.trunc(seconds);
    let minutes = Math.floor(sec / 60);
    let extraSeconds = sec % 60;
    const result = `${minutes}.${extraSeconds}`;
    return result;
  }

  const handleCreateAdmin = async (values: any) => {
    setIsLoading(true);
    try {

      const contentState = editorState.getCurrentContent();
      const raw = convertToRaw(contentState);

      if (params.id) {
        let unique: any = uuid1() + "";
        unique = unique.split("-");
        unique = unique[0].slice(0, 6);

        const uploadDataArray = [
          {
            finalVideo: finalVideo.video_1080,
            initialVideo: initialVideo.video_1080,
          },
          {
            finalVideo: finalVideo.video_720,
            initialVideo: initialVideo.video_720,
          },
          {
            finalVideo: finalVideo.video_480,
            initialVideo: initialVideo.video_480,
          },
          {
            finalVideo: finalThumbnailImage,
            initialVideo: initialThumbnailImage,
          },
        ];

        const resp = Promise.all(
          uploadDataArray.map(async (item) => {
            if (item.finalVideo.url === "") {
              let key, contentType, type;
              if (item === uploadDataArray[3]) {
                key =
                  `thumbnail/${unique}_${item.finalVideo.file.name}`.replace(
                    / /g,
                    "_"
                  );
                contentType = "image/*";
                type = "put";
              } else {
                key = `video/${unique}_${item.finalVideo.file.name}`.replace(
                  / /g,
                  "_"
                );
                contentType = "video/*";
                type = "put";
              }

              let res;
              const data: any = await getS3SignUrl(key, contentType, type);
              if (data) {
                res = await pushToS3(data, item.finalVideo.file);
              }

              if (res.status === 200) {
                console.log("key", item.initialVideo.key);
                await deleteObjectFromS3Api({
                  key: item.initialVideo?.key,
                });
              }
            }
          })
        );

        resp
          .then(async () => {
            const result: any = await handleUpdateVideoApi(params.id, {
              video_1080: finalVideo.video_1080.file
                ? `${unique}_${finalVideo.video_1080.file.name}`.replace(
                  / /g,
                  "_"
                )
                : initialVideo.video_1080.key.split("/")[1],
              video_720: finalVideo.video_720.file
                ? `${unique}_${finalVideo.video_720.file.name}`.replace(
                  / /g,
                  "_"
                )
                : initialVideo.video_720.key.split("/")[1],
              video_480: finalVideo.video_480.file
                ? `${unique}_${finalVideo.video_480.file.name}`.replace(
                  / /g,
                  "_"
                )
                : initialVideo.video_480.key.split("/")[1],
              thumbnail: finalThumbnailImage.file
                ? `${unique}_${finalThumbnailImage.file.name}`.replace(
                  / /g,
                  "_"
                )
                : initialThumbnailImage.key.split("/")[1],
              videoName: values.videoName,
              courseTag: values.courseTag,
              category: values.category,
              subCategory: values.subCategory,
              skills: JSON.stringify(values.skills),
              level: values.level,
              dynamicFields: JSON.stringify(values.dynamicFields),
              about: JSON.stringify(raw),
              duration: convertStoMs(videoDuration.video_1080),
              courseId: courseId,
              authorId: values.author,
              authorName: authorName,
            });
            if (result) {
              toaster.successToast("Video Updated Successfully");
              window.history.back();
              setIsLoading(false);
            } else {
              toaster.errorToast("Something went wrong");
            }
          })
          .catch((error) => {
            // If any promise fails, this block will be executed
            console.error("One or more promises failed", error);
            toaster.errorToast(
              "one of the video or thumbnail not uploaded, Try again"
            );
            setIsLoading(false);
          });
      } else {
        let unique: any = uuid1() + "";
        unique = unique.split("-");
        unique = unique[0].slice(0, 6);

        const uploadDataArray = [
          values.video_1080,
          values.video_720,
          values.video_480,
          values.thumbnail,
        ];

        const resp = Promise.all(
          uploadDataArray.map(async (item) => {
            // console.log("item :>> ", item);
            let key, contentType, type;
            if (item.type.split("/")[0] === "image") {
              key = `thumbnail/${unique}_${item.name}`.replace(/ /g, "_");
              contentType = "image/*";
              type = "put";
            } else if (item.type.split("/")[0] === "video") {
              key = `video/${unique}_${item.name}`.replace(/ /g, "_");
              contentType = "video/*";
              type = "put";
            }

            const data: any = await getS3SignUrl(key, contentType, type);
            if (data) {
              return await pushToS3(data, item);
            }
          })
        );

        resp
          .then(async () => {
            const result: any = await createVideoApi({
              videoName: values.videoName,
              courseTag: values.courseTag,
              category: values.category,
              subCategory: values.subCategory,
              skills: JSON.stringify(values.skills),
              level: values.level,
              dynamicFields: JSON.stringify(values.dynamicFields),
              about: JSON.stringify(raw),
              videoDuration_1080: convertStoMs(videoDuration.video_1080),
              video_1080: `${unique}_${values.video_1080.name}`.replace(
                / /g,
                "_"
              ),
              video_720: `${unique}_${values.video_720.name}`.replace(
                / /g,
                "_"
              ),
              video_480: `${unique}_${values.video_480.name}`.replace(
                / /g,
                "_"
              ),
              thumbnail: `${unique}_${values.thumbnail.name}`.replace(
                / /g,
                "_"
              ),
              authorId: values.author,
              authorName: authorName,
              courseTypeFilter: "skills",
            });

            if (result) {
              toaster.successToast("Video added Successfully");
              window.history.back();
              setIsLoading(false);
            } else {
              toaster.errorToast("Something went wrong");
            }
          })
          .catch((error) => {
            // If any promise fails, this block will be executed
            toaster.errorToast(
              "one of the video or thumbnail not uploaded, Try again"
            );
            setIsLoading(false);
            console.error("One or more promises failed", error);
          });
      }
    } catch (error: any) {
      toaster.errorToast(error.message);
      setIsLoading(false);
      console.log("final catch video create and update", error);
    }
  };

  function toTitleCase(inputString: string) {
    return inputString
      .replace(/_/g, " ") // Replace underscores with spaces
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const getData = async (id: string) => {
    setIsLoading(true);
    try {
      const res: any = await getVideoDetailsById(id);
      if (res) {
        setCourseId(res[0]?.courseDetails[0]._id);
        const res1: any = await getCategoryByIdApi(
          res[0].courseDetails[0].categoryId
        );
        getSubCategory(res1.tag);
        const thumbnailKey = `thumbnail/${res[0].courseDetails[0].thumbnail_url}`;
        if (thumbnailKey) {
          {
            const ex = res[0].courseDetails[0].thumbnail_url.split(".");
            const contentType = `image/${ex}`;
            const type = "get";
            const data: any = await getS3SignUrl(
              thumbnailKey,
              contentType,
              type
            );
            setInitialThumbnailImage({ key: thumbnailKey, url: data });
            setFinalThumbnailImage({ key: thumbnailKey, url: data });
            setThumbnailPreview(data);
          }
        }
        console.log("1080pV", res[0].keys.link_1080p);
        console.log("720pV", res[0].keys.link_720p);
        console.log("480pV", res[0].keys.link_480p);
        const video_1080_key = res[0].links[0].link_1080p;
        if (res[0].links[0].link_1080p) {
          console.log("1080pV");
          {
            setInitialVideo((prev: any) => ({
              ...prev,
              video_1080: {
                key: `video/${res[0].keys.link_1080p}`,
                url: video_1080_key,
              },
            }));
            setFinalVideo((prev: any) => ({
              ...prev,
              video_1080: {
                key: `video/${res[0].links.keys}`,
                url: video_1080_key,
              },
            }));
            setVideoPreview((prev: any) => ({
              ...prev,
              video_1080: video_1080_key,
            }));
          }
        }

        const video_720_key = res[0].links[1].link_720p;
        if (res[0].links[1].link_720p) {
          console.log("720pV");

          {
            setInitialVideo((prev: any) => ({
              ...prev,
              video_720: {
                key: `video/${res[0].keys.link_720p}`,
                url: video_720_key,
              },
            }));
            setFinalVideo((prev: any) => ({
              ...prev,
              video_720: {
                key: `video/${res[0].keys.link_720p}`,
                url: video_720_key,
              },
            }));
            setVideoPreview((prev: any) => ({
              ...prev,
              video_720: video_720_key,
            }));
          }
        }

        const video_480_key = res[0].links[2].link_480p;
        if (res[0].links[2].link_480p) {
          console.log("480pV");
          {
            setInitialVideo((prev: any) => ({
              ...prev,
              video_480: {
                key: `video/${res[0].keys.link_480p}`,
                url: video_480_key,
              },
            }));
            setFinalVideo((prev: any) => ({
              ...prev,
              video_480: {
                key: `video/${res[0].keys.link_480p}`,
                url: video_480_key,
              },
            }));
            setVideoPreview((prev: any) => ({
              ...prev,
              video_480: video_480_key,
            }));
          }
        }

        setInitialFormValues({
          video_1080: {},
          video_720: {},
          video_480: {},
          thumbnail: {},
          videoName: res[0].title ? res[0].title : "",
          courseTag: res[0].courseDetails[0].classType ? res[0].courseDetails[0].classType : "",
          category: res1.tag ? res1.tag : "",
          subCategory: res1._id ? res1._id : "",
          skills: res[0].courseDetails[0].skills.map((item: any) => {
            return { value: item ? item : "", label: item ? toTitleCase(item) : "" };
          }),
          level: res[0].courseDetails[0].level ? res[0].courseDetails[0].level : "",
          author: res[0].courseDetails[0].authorId ? res[0].courseDetails[0].authorId : "",
          dynamicFields: res[0].chapters.map((item: any) => {
            return { duration: item.duration, chapter: item.title };
          }),
        });

        setAuthorName(res[0].courseDetails[0].authorName ? res[0].courseDetails[0].authorName : "");
        setAuthorState(res[0].courseDetails[0].authorId ? res[0].courseDetails[0].authorId : "");
        setEditorState(
          res[0].courseDetails[0].description ? EditorState.createWithContent(
            convertFromRaw(JSON.parse(res[0].courseDetails[0].description))
          ) : EditorState.createEmpty()
        );

        EditorState.createEmpty()
        setClassTypeState(res[0].courseDetails[0].classType ? res[0].courseDetails[0].classType : "");
        setCategoryState(res1.tag ? res1.tag : "");
        setLevelState(res[0].courseDetails[0].level ? res[0].courseDetails[0].level : "");
        setSubCategoryState(res1._id ? res1._id : "");
        setIsLoading(false);
      }
    } catch (error: any) {
      toaster.errorToast(error.message);
      setIsLoading(false);
    }
  };

  const getAllSkills = async () => {
    try {
      setIsLoading(true);
      const res: any = await getAllSkillsApi();
      if (!res) {
        toaster.errorToast("Skills not available");
      }
      setSkillsOptions(
        res.map((option: any) => {
          return {
            value: option.value,
            label: option.label,
          };
        })
      );
      setIsLoading(false);
    } catch (error: any) {
      toaster.errorToast(error.message);
      setIsLoading(false);
    }
  };

  const getSubCategory = async (tag: string) => {
    try {
      if (!tag) {
        setSubCategoryOptions([
          {
            value: "",
            label: "",
          },
        ]);
        return;
      }
      const res: any = await getAllCategoryByTagApi({ tag: tag });
      if (!res) {
        toaster.errorToast("Subcategories not available");
      }
      setSubCategoryOptions(
        res?.map((option: any) => {
          return {
            value: option._id,
            label: option.category,
          };
        })
      );

    } catch (error: any) {
      toaster.errorToast(error.message);
    }
  };

  const getAuthorsList = async () => {
    try {
      setIsLoading(true);
      const res: any = await getAuthors();
      if (!res) {
        toaster.errorToast("Authors not found");
      }
      setAuthorsOptions(
        res?.map((option: any) => {
          return {
            value: option._id,
            label: option.name,
          };
        })
      );
      setIsLoading(false);
    } catch (error: any) {
      toaster.errorToast(error.message);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (params.id) {
      getData(params.id);
    }
  }, [params.id]);

  React.useEffect(() => {
    getAllSkills();
    getAuthorsList();
  }, []);

  return (
    <>
      <Navbar
        flag={false}
        brandText="adminform"
        sideBarUseState={sideBarUseState}
        setSideBarUseState={setSideBarUseState}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <Card extra={"w-full pb-6 p-4 h-full mt-4 pt-10"}>
          <header className="relative flex items-center justify-between ps-[10%] sm:ps-20">
            {params.id ? (
              <div className="text-xl font-bold text-brand-500 dark:text-white">
                Edit Video
              </div>
            ) : (
              <div className="text-xl font-bold text-brand-500 dark:text-white">
                Upload Video
              </div>
            )}
          </header>
          <div className="p-6 sm:p-10 sm:pb-5 sm:pe-20 sm:ps-20">
            <Formik
              enableReinitialize={true}
              initialValues={initialFormValues}
              onSubmit={(values) => handleCreateAdmin(values)}
              validationSchema={videoFormSchema}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col sm:flex-row sm:justify-between">
                    <div className="w-full sm:mb-3 sm:me-6">
                      <VideoInput
                        videoPreview={videoPreview.video_1080}
                        videoRef={videoRef_1080}
                        params={params}
                        setFieldValue={setFieldValue}
                        setFinalVideo={setFinalVideo}
                        setVideoPreview={setVideoPreview}
                        handleBlur={handleBlur}
                        nameVideo={"video_1080"}
                        values={values}
                        errors={errors}
                        touched={touched}
                        setVideoDuration={setVideoDuration}
                        setIsValidation={setIsValidation}
                      />
                    </div>
                    <div className="w-full sm:mb-3 sm:ms-6">
                      <VideoInput
                        videoPreview={videoPreview.video_720}
                        videoRef={videoRef_720}
                        params={params}
                        setFieldValue={setFieldValue}
                        setFinalVideo={setFinalVideo}
                        setVideoPreview={setVideoPreview}
                        handleBlur={handleBlur}
                        nameVideo={"video_720"}
                        values={values}
                        errors={errors}
                        touched={touched}
                        setVideoDuration={setVideoDuration}
                        setIsValidation={setIsValidation}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row sm:justify-between">
                    <div className="w-full sm:mb-3 sm:me-6">
                      <div className="mt-4">
                        <VideoInput
                          videoPreview={videoPreview.video_480}
                          videoRef={videoRef_480}
                          params={params}
                          setFieldValue={setFieldValue}
                          setFinalVideo={setFinalVideo}
                          setVideoPreview={setVideoPreview}
                          handleBlur={handleBlur}
                          nameVideo={"video_480"}
                          values={values}
                          errors={errors}
                          touched={touched}
                          setVideoDuration={setVideoDuration}
                          setIsValidation={setIsValidation}
                        />
                      </div>
                    </div>
                    <div className="w-full sm:mb-3 sm:ms-6">
                      <div className="mt-4">
                        <div>
                          {thumbnailPreview && (
                            <>
                              <p className="mb-2 text-sm font-bold text-navy-700 dark:text-white">
                                {"Thumbnail"}
                              </p>
                              <div
                                className="image-preview mr-4 h-[120px] w-fit sm:h-[200px]"
                                style={{
                                  // width: "300px",
                                  // height: "200px",
                                  padding: "2px",
                                  border: "2px solid #9CA3AF",
                                  borderRadius: "4px",
                                }}
                              >
                                <img
                                  src={thumbnailPreview}
                                  style={{
                                    objectFit: "contain",
                                    height: "100%",
                                    width: "auto",
                                    cursor: "pointer",
                                  }}
                                  alt="img"
                                />
                              </div>
                            </>
                          )}
                        </div>
                        <div className="mt-4">
                          <label>
                            <div
                              style={{
                                border: "1px solid #9CA3AF",
                                borderRadius: "4px",
                                cursor: "pointer",
                              }}
                              className="mt-2 h-20 rounded-xl border bg-white/0 p-3 text-sm outline-none"
                            >
                              <div
                                className="flex items-center justify-center"
                                style={{ cursor: "pointer" }}
                              >
                                <div>
                                  <div
                                    className="mr-4 mt-3 flex items-center justify-center"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div className="mb-3">
                                      <img
                                        src={uploadCloud}
                                        alt="Upload Cloud"
                                        height="24px"
                                        width="24px"
                                        className="mr-2"
                                      />
                                    </div>
                                    <div className="mb-3 text-center">
                                      {!params.id ? (
                                        <>
                                          <a style={{ color: "#FF9966" }}>
                                            Click here {""}
                                          </a>
                                          to upload video thumbnail
                                        </>
                                      ) : (
                                        <>
                                          <a style={{ color: "#FF9966" }}>
                                            Click here {""}
                                          </a>
                                          to change video thumbnail
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <input
                                    // required
                                    accept="image/*"
                                    style={{
                                      backgroundColor:
                                        "rgba(242, 242, 242, 0.5)",
                                      display: "none",
                                    }}
                                    className="mt-2 h-12 w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                                    name="thumbnail"
                                    type="file"
                                    id="thumbnail"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "thumbnail",
                                        event.target.files[0]
                                      );
                                      const file = event.target.files[0];
                                      setFinalThumbnailImage({
                                        key: "",
                                        url: "",
                                        file: file,
                                      });
                                      if (file) {
                                        const reader = new FileReader();
                                        reader.onload = (e) => {
                                          setThumbnailPreview(e.target.result);
                                        };
                                        reader.readAsDataURL(file);
                                      } else {
                                        setThumbnailPreview(null);
                                      }
                                      if (event.target.files[0]) {
                                        setIsValidation((prev: any) => ({
                                          ...prev,
                                          thumbnail: true,
                                        }));
                                      }
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </div>
                          </label>

                          <ErrorMessage
                            name="thumbnail"
                            component="div"
                            className="error-input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3 flex flex-col sm:flex-row sm:justify-between">
                    <div className="w-full sm:mb-3 sm:me-6">
                      <div className="input-container">
                        <input
                          required
                          className="w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          name="videoName"
                          type="text"
                          id="videoName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.videoName}
                        />
                        <label
                          htmlFor="videoName"
                          className={`input-custom-label dark:text-white ${values?.videoName ? "filled" : ""
                            }`}
                        >
                          Video Name
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.videoName && touched.videoName
                          ? errors.videoName
                          : null}
                      </div>
                    </div>
                    <div className="w-full sm:mb-3 sm:ms-6">
                      <div className="input-container">
                        <Select
                          onBlur={handleBlur}
                          isSearchable={false}
                          options={classType}
                          // defaultValue={classType[0]}
                          onChange={(selectedOption) => {
                            setClassTypeState(selectedOption.value);
                            values.courseTag = selectedOption.value;
                            setFieldValue("courseTag", selectedOption.value);
                          }}
                          value={classType.filter(function (option: any) {
                            return option.value == classTypeState;
                          })}
                          placeholder=""
                          name="courseTag"
                          id="courseTag"
                          styles={{
                            dropdownIndicator: (base: any) => ({
                              ...base,
                              marginTop: "-24px", // Add padding to the bottom of the dropdown indicator
                            }),
                            // Fixes the overlapping problem of the component
                            menu: (provided: any) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                            control: (provided: any, state: any) => ({
                              ...provided,
                              height: "64px", // Adjust the height as needed
                              paddingTop: "8px",
                              borderColor: "#9ca3af",
                              // This line disable the blue border
                              boxShadow: state.isFocused
                                ? "0 0 0 2px #9ca3af"
                                : "#9ca3af",
                              "&:hover": {
                                // border: state.isFocused ? 0 : 0,
                              },
                            }),
                            option: (provided: any, state: any) => ({
                              ...provided,
                              fontSize: "16px",
                              backgroundColor: state.isSelected
                                ? "#f2f3f7"
                                : "white", // Change the background color here
                              color: "black", // Change the text color here
                              "&:hover": {
                                backgroundColor: "#f2f3f7", // Change the background color on hover
                              },
                            }),
                          }}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                        <label
                          htmlFor="courseTag"
                          className={`input-custom-label dark:text-white ${values?.courseTag ? "filled" : ""
                            }`}
                        >
                          Course Tag
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.courseTag && touched.courseTag
                          ? errors.courseTag
                          : null}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row sm:justify-between">
                    <div className="w-full sm:mb-3 sm:me-6">
                      <div className="input-container">
                        <Select
                          isSearchable={false}
                          options={categoryTag}
                          // defaultValue={categoryTag[0]}
                          onChange={(selectedOption) => {
                            setCategoryState(selectedOption.value);
                            values.category = selectedOption.value;
                            setFieldValue("category", selectedOption.value);
                            getSubCategory(selectedOption.value);
                            values.subCategory = "";
                            // setSubCategoryState("");
                          }}
                          value={categoryTag.filter(function (option: any) {
                            return option.value == categoryState;
                          })}
                          placeholder=""
                          name="category"
                          id="category"
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: (provided: any) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                            dropdownIndicator: (base: any) => ({
                              ...base,
                              marginTop: "-24px", // Add padding to the bottom of the dropdown indicator
                            }),
                            control: (provided: any, state: any) => ({
                              ...provided,
                              height: "64px", // Adjust the height as needed
                              paddingTop: "8px",
                              borderColor: "#9ca3af",
                              // This line disable the blue border
                              boxShadow: state.isFocused
                                ? "0 0 0 2px #9ca3af"
                                : "#9ca3af",
                              "&:hover": {
                                // border: state.isFocused ? 0 : 0,
                              },
                            }),
                            option: (provided: any, state: any) => ({
                              ...provided,
                              fontSize: "16px",
                              backgroundColor: state.isSelected
                                ? "#f2f3f7"
                                : "white", // Change the background color here
                              color: "black", // Change the text color here
                              "&:hover": {
                                backgroundColor: "#f2f3f7", // Change the background color on hover
                              },
                            }),
                          }}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                        <label
                          htmlFor="category"
                          className={`input-custom-label dark:text-white ${values?.category ? "filled" : ""
                            }`}
                        >
                          Category
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.category && touched.category
                          ? errors.category
                          : null}
                      </div>
                    </div>
                    <div className="w-full sm:mb-3 sm:ms-6">
                      <div className="input-container">
                        <Select
                          isSearchable={false}
                          options={subCategoryOptions}
                          onChange={(selectedOption) => {
                            setSubCategoryState(selectedOption.value);
                            values.subCategory = selectedOption.value;
                            setFieldValue("subCategory", selectedOption.value);
                          }}
                          value={subCategoryOptions.filter(function (
                            option: any
                          ) {
                            return option.value == subCategoryState;
                          })}
                          placeholder=""
                          name="subCategory"
                          id="subCategory"
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: (provided: any) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                            dropdownIndicator: (base: any) => ({
                              ...base,
                              marginTop: "-24px", // Add padding to the bottom of the dropdown indicator
                            }),
                            control: (provided: any, state: any) => ({
                              ...provided,
                              height: "64px", // Adjust the height as needed
                              paddingTop: "8px",
                              borderColor: "#9ca3af",
                              // This line disable the blue border
                              boxShadow: state.isFocused
                                ? "0 0 0 2px #9ca3af"
                                : "#9ca3af",
                              "&:hover": {
                                // border: state.isFocused ? 0 : 0,
                              },
                            }),
                            option: (provided: any, state: any) => ({
                              ...provided,
                              fontSize: "16px",
                              backgroundColor: state.isSelected
                                ? "#f2f3f7"
                                : "white", // Change the background color here
                              color: "black", // Change the text color here
                              "&:hover": {
                                backgroundColor: "#f2f3f7", // Change the background color on hover
                              },
                            }),
                          }}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                        <label
                          htmlFor="subCategory"
                          className={`input-custom-label dark:text-white ${values?.subCategory ? "filled" : ""
                            }`}
                        >
                          Sub Category
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.subCategory && touched.subCategory
                          ? errors.subCategory
                          : null}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row sm:justify-between">
                    <div className="w-full sm:mb-3 sm:me-6">
                      <div className="input-container">
                        <Select
                          isSearchable={false}
                          options={skillsOptions}
                          onChange={(selectedOption) => {
                            values.skills = selectedOption;
                            setFieldValue("skills", selectedOption);
                          }}
                          onBlur={handleBlur}
                          value={values.skills}
                          placeholder=""
                          name="skills"
                          id="skills"
                          isMulti
                          // className="basic-multi-select"
                          classNamePrefix="select"
                          styles={{
                            // Fixes the overlapping problem of the component
                            placeholder: (provided: any) => ({
                              ...provided,
                              fontSize: "16px",
                              color: "#6f81a5",
                            }),

                            menu: (provided: any) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                            control: (provided: any, state: any) => ({
                              ...provided,
                              height: `${values.skills.length > 0 ? null : "64px"
                                }`,
                              borderRadius: "4px",
                              fontSize: "16px",
                              paddingTop: `${values.skills.length > 0 ? "30px" : "0px"
                                }`,
                              borderColor: "#9ca3af",
                              maxHeight: "fit-content",
                              // This line disable the blue border
                              boxShadow: state.isFocused
                                ? "0 0 0 2px #9ca3af"
                                : "#9ca3af",
                              "&:hover": {
                                // border: state.isFocused ? 0 : 0,
                              },
                            }),
                            option: (provided: any, state: any) => ({
                              ...provided,
                              fontSize: "16px",
                              backgroundColor: state.isSelected
                                ? "#f2f3f7"
                                : "white", // Change the background color here
                              color: "black", // Change the text color here
                              "&:hover": {
                                backgroundColor: "#f2f3f7", // Change the background color on hover
                              },
                            }),
                          }}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                        <label
                          htmlFor="skills"
                          className={`input-custom-label dark:text-white ${values?.skills.length > 0 ? "filled" : ""
                            }`}
                        >
                          Skills
                        </label>
                      </div>
                      <ErrorMessage
                        name="skills"
                        component="div"
                        className="error-input"
                      />
                    </div>
                    <div className="mt-3 w-full sm:mb-3 sm:ms-6 sm:mt-0">
                      <div className="input-container">
                        <Select
                          isSearchable={false}
                          options={Level}
                          defaultValue={Level[0]}
                          onChange={(selectedOption) => {
                            setLevelState(selectedOption.value);
                            values.level = selectedOption.value;
                            setFieldValue("level", selectedOption.value);
                          }}
                          value={Level.filter(function (option: any) {
                            return option.value == levelState;
                          })}
                          placeholder=""
                          name="level"
                          id="level"
                          styles={{
                            // Fixes the overlapping problem of the component
                            // placeholder: (provided: any) => ({
                            //   ...provided,
                            //   fontSize: "16px",
                            //   color: "#6f81a5",
                            // }),
                            dropdownIndicator: (base: any) => ({
                              ...base,
                              marginTop: "-24px", // Add padding to the bottom of the dropdown indicator
                            }),
                            menu: (provided: any) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                            control: (provided: any, state: any) => ({
                              ...provided,
                              height: "64px", // Adjust the height as needed
                              paddingTop: "8px",
                              borderColor: "#9ca3af",
                              // This line disable the blue border
                              boxShadow: state.isFocused
                                ? "0 0 0 2px #9ca3af"
                                : "#9ca3af",
                              "&:hover": {
                                // border: state.isFocused ? 0 : 0,
                              },
                            }),
                            option: (provided: any, state: any) => ({
                              ...provided,
                              fontSize: "16px",
                              backgroundColor: state.isSelected
                                ? "#f2f3f7"
                                : "white", // Change the background color here
                              color: "black", // Change the text color here
                              "&:hover": {
                                backgroundColor: "#f2f3f7", // Change the background color on hover
                              },
                            }),
                          }}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                        <label
                          htmlFor="level"
                          className={`input-custom-label dark:text-white ${values?.level ? "filled" : ""
                            }`}
                        >
                          Level
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.level && touched.level ? errors.level : null}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row sm:justify-between">
                    <div className="w-full sm:mb-3 sm:me-6">
                      <div className="input-container">
                        <Select
                          isSearchable={false}
                          options={authorsOptions}
                          onChange={(selectedOption) => {
                            setAuthorState(selectedOption.value);
                            setAuthorName(selectedOption.label);
                            values.author = selectedOption.value;
                            setFieldValue("author", selectedOption.value);
                          }}
                          value={authorsOptions.filter(function (option: any) {
                            return option.value == authorState;
                          })}
                          placeholder=""
                          name="author"
                          id="author"
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: (provided: any) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                            dropdownIndicator: (base: any) => ({
                              ...base,
                              marginTop: "-24px", // Add padding to the bottom of the dropdown indicator
                            }),
                            control: (provided: any, state: any) => ({
                              ...provided,
                              height: "64px", // Adjust the height as needed
                              paddingTop: "8px",
                              borderColor: "#9ca3af",
                              // This line disable the blue border
                              boxShadow: state.isFocused
                                ? "0 0 0 2px #9ca3af"
                                : "#9ca3af",
                              "&:hover": {
                                // border: state.isFocused ? 0 : 0,
                              },
                            }),
                            option: (provided: any, state: any) => ({
                              ...provided,
                              fontSize: "16px",
                              backgroundColor: state.isSelected
                                ? "#f2f3f7"
                                : "white", // Change the background color here
                              color: "black", // Change the text color here
                              "&:hover": {
                                backgroundColor: "#f2f3f7", // Change the background color on hover
                              },
                            }),
                          }}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                        <label
                          htmlFor="author"
                          className={`input-custom-label dark:text-white ${values?.author ? "filled" : ""
                            }`}
                        >
                          Author
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.author && touched.author ? errors.author : null}
                      </div>
                    </div>
                    <div className="w-full sm:mb-3 sm:ms-6"></div>
                  </div>

                  <div className="mt-3">
                    <h2 className="mb-2">About:</h2>
                    <div
                      style={{
                        border: "1px solid #9ca3af",
                        minHeight: "200px",
                        marginBottom: "30px",
                        borderRadius: "3px",
                      }}
                    >
                      <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={(e) => setEditorState(e)}
                        toolbar={toolbar}
                        editorStyle={{
                          padding: "10px",
                        }}
                      />
                    </div>
                  </div>

                  <h2 className="mt-3">Add Video Chapters & Durations:</h2>
                  {values.dynamicFields.map((field: any, index: any) => (
                    <div
                      className="mt-3 flex flex-col sm:flex-row sm:justify-between"
                      key={index}
                    >
                      <div className="mb-3 w-full sm:me-6">
                        <div className="input-container">
                          <input
                            required
                            className="w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                            name={`dynamicFields[${index}].duration`}
                            type="number"
                            id={`dynamicFields[${index}].duration`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={field?.duration}
                          />
                          <label
                            htmlFor="duration"
                            className={`input-custom-label dark:text-white ${field?.duration ? "filled" : ""
                              }`}
                          >
                            Duration
                          </label>
                        </div>
                        <ErrorMessage
                          name={`dynamicFields[${index}].duration`}
                          component="div"
                          className="error-input"
                        />
                      </div>
                      <div className="mb-3 flex w-full flex-row sm:ms-6">
                        <div className="basis-11/12">
                          <div className="input-container">
                            <input
                              required
                              className="w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                              name={`dynamicFields[${index}].chapter`}
                              type="text"
                              id={`dynamicFields[${index}].chapter`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={field?.chapter}
                            />
                            <label
                              htmlFor={`dynamicFields[${index}].chapter`}
                              className={`input-custom-label dark:text-white ${field?.chapter ? "filled" : ""
                                }`}
                            >
                              Chapter
                            </label>
                          </div>
                          <ErrorMessage
                            name={`dynamicFields[${index}].chapter`}
                            component="div"
                            className="error-input"
                          />
                        </div>
                        <div className="mt-4 basis-1/12 pl-2">
                          <Button
                            className="py-0.1 rounded-full bg-orange-700 px-2 text-xl font-extrabold text-white"
                            type="button"
                            onClick={() => {
                              const newFields = [...values.dynamicFields];
                              newFields.splice(index, 1);
                              setFieldValue("dynamicFields", newFields);
                            }}
                          >
                            -
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}

                  <Button
                    className="rounded bg-orange-700 px-3 py-1 text-3xl font-extrabold text-white"
                    type="button"
                    onClick={() => {
                      setFieldValue("dynamicFields", [
                        ...values.dynamicFields,
                        { duration: "", chapter: "" },
                      ]);
                    }}
                  >
                    +
                  </Button>

                  <div className="button-save-cancel mt-3 flex justify-end">
                    <Button
                      className=" cancel-button my-2 ms-1 sm:my-0"
                      onClick={() => {
                        // navigate("/admin/Videos")
                        window.history.back();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      className="save-button my-2 ms-1 bg-brand-500 dark:bg-brand-400 sm:my-0"
                    >
                      Save
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Card>
      )}
    </>
  );
};

export default VideoForm;
