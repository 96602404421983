import { useEffect, useRef, useState } from "react";
import { getAllUser } from "../../../services/customAPI";
import ReactPaginate from "react-paginate";
import Loader from "components/loader/loader";

import { createColumnHelper } from "@tanstack/react-table";
import eyeview from "../../../assets/svg/eyeView.svg";
import dummyProfile from "../../../assets/svg/dummyProfile.svg";
import "./userlist.css";
import Navbar from "../../../components/navbar";
import { toaster } from "components/common/toaster";
import Header from "components/common/TableHeader";
import Table from "components/common/TableBody";
// import LimitSelect from "components/common/paginationLimitSelect";
import { useNavigate } from "react-router-dom";

type RowObj = {
  id: any;
  name: customFieldType1;
  email: string;
  mobileNumber: string;
  plan: string;
  createdAt: string;
  profileImgUrl: string;
  action: customFieldType2;
};

type customFieldType1 = {
  name: string;
  path?: string;
};

type customFieldType2 = {
  id: String;
};

const ImageWithFallback: React.FC<{
  src: string;
  alt: string;
  fallbackSrc: string;
  className?: any;
}> = ({ src, alt, fallbackSrc, className }) => {
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement>) => {
    // This function is called when the original image fails to load.
    // Set the source (src) of the image to the fallback source.
    event.currentTarget.src = fallbackSrc;
  };
  return (
    <img src={src} alt={alt} onError={handleImageError} className={className} />
  );
};

const Users = (props: any) => {
  const { sideBarUseState, setSideBarUseState } = props;
  const [isLoading, setIsLoading] = useState(false);
  // const [tableData, setTableData] = useState([]);
  const currentPage = useRef<number>();
  // const [limit, setLimit] = useState(10);
  // const [pageCount, setPageCount] = useState(1);
  // const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const [pageItemStartNumber, setPageItemStartNumber] = useState<any>(0);
  const [pageItemEndNumber, setPageItemEndNumber] = useState<any>(0);
  // const [plan, setPlan] = useState("all");
  const [state, setState] = useState({
    plan: "all",
    pageCount: 1,
    searchText: "",
    tableData: [],
    limit: 10,
    noData: false,
  });

  const firstRender = useRef(true);
  const planOptions = [
    { value: "all", label: "All" },
    { value: "activated", label: "Subscribed" },
    { value: "not_subscribed", label: "Not Subscribed" },
    { value: "initiated", label: "Initiated" },
  ];

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Name</p>
      ),
      cell: (info: any) => (
        <p
          className="text-sm font-bold text-navy-700 dark:text-white"
          style={{ display: "flex", alignItems: "center" }}
        >
          {info.getValue()?.path === "" ? (
            <div
              style={{
                width: "40px",
                height: "40px",
                padding: "1px",
                borderRadius: "4px",
                display: "inline-block",
                marginRight: "6px",
                backgroundColor: "#F4F7FE",
              }}
            >
              <span
                style={{
                  position: "relative",
                  left: "14px",
                  top: "8.5px",
                  fontSize: "18px",
                }}
              >
                {info.getValue()?.name.slice(0, 1).toUpperCase()}
              </span>
            </div>
          ) : (
            <div
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "4px",
                display: "inline-block",
                marginRight: "6px",
                alignItems: "center",
              }}
            >
              <ImageWithFallback
                src={info.getValue()?.path}
                alt="img"
                fallbackSrc={dummyProfile}
                className="h-[40px]"
              />
              {/* <img
                src={info.getValue()?.path}
                style={{
                  objectFit: "fill",
                  height: "100%",
                  width: "auto",
                  borderRadius: "4px",
                }}
                alt="img"
              /> */}
            </div>
          )}

          <span>{info.getValue()?.name}</span>
        </p>
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Email-Id
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("mobileNumber", {
      id: "mobileNumber",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Mobile Number
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue() ? info.getValue() : "N/A"}
        </p>
      ),
    }),
    columnHelper.accessor("plan", {
      id: "plan",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Plan</p>
      ),
      cell: (info) => (
        <p
          className={
            info.getValue() === "activated"
              ? "activated text-sm font-bold text-navy-700 dark:text-white"
              : info.getValue() === "not_subscribed" || info.getValue() === ""
              ? "not_subscribed text-sm font-bold text-navy-700 dark:text-white"
              : "initiated text-sm font-bold text-navy-700 dark:text-white"
          }
        >
          {info.getValue() === "activated"
            ? "Subscribed"
            : info.getValue() === "not_subscribed" || info.getValue() === ""
            ? "Not Subscribed"
            : "Initiated"}
        </p>
      ),
    }),
    columnHelper.accessor("createdAt", {
      id: "createdAt",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Created On
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue() ? info.getValue().slice(0, 10) : ""}
        </p>
      ),
    }),
    columnHelper.accessor("action", {
      id: "action",
      header: () => (
        <p className="text-end text-sm font-bold text-gray-600 dark:text-white">
          Action
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <img
            style={{ cursor: "pointer" }}
            src={eyeview}
            onClick={() => {
              // console.log("View", info.row.original.action.id);
              navigate(
                `/admin/users/userDetails/${info.row.original.action.id}`
              );
            }}
          />
        </div>
      ),
    }),
  ]; // eslint-disable-next-line

  const setPageItemRange = (currPageNumber: number, maxItemRange: number) => {
    let startNumber = currPageNumber * state.limit - state.limit + 1;
    if (startNumber < 0) {
      startNumber = 0;
    }
    setPageItemStartNumber(
      startNumber.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
    );

    const endNumber = currPageNumber * state.limit;
    setPageItemEndNumber(
      Math.min(endNumber, maxItemRange).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
    );
  };

  function handlePageClick(e: any) {
    setIsLoading(true);
    //console.log(e);
    currentPage.current = e.selected + 1;
    getUserListing(state.plan, state.limit);
    //    if (searchText !== "") {
    //      searchDriverFunction();
    //    } else {
    //      getPaginatedDriverData();
    //    }

    setIsLoading(false);
  }

  function convertUtcToIst(utcTimeStr: string) {
    try {
      if (utcTimeStr) {
        const utcDate = new Date(utcTimeStr);
        const istOffsetMinutes = 330; // IST offset in minutes (UTC+5:30)

        const istTime = new Date(
          utcDate.getTime() + istOffsetMinutes * 60 * 1000
        );
        return istTime.toISOString(); // Return in ISO format
      }
    } catch (error: any) {
      toaster.errorToast(error);
      console.error("Invalid UTC time value:", utcTimeStr);
      return null; // Handle the error gracefully, return null, or throw a custom error
    }
  }

  function convertToUsableArray(Array: Array<any>) {
    const res = Array.map((user) => {
      // //console.log("Data Users", user);
      return {
        name: {
          name: user?.fullName,
          path: user?.profileImgUrl ? user?.profileImgUrl : "",
        },
        email: user?.email,
        mobileNumber: user?.phoneNumber,
        plan: user?.subscription ? user?.subscription : "",
        createdAt: convertUtcToIst(user?.createdAt?.substring(0, 10))
          ? convertUtcToIst(user?.createdAt?.substring(0, 10))
          : "N/A",
        action: {
          id: user?._id,
        },
      };
    });
    console.log("Usablearray", res);
    return res;
  }

  const getUserListing = async (plan: string, limit: number) => {
    // //console.log(
    //   "plan, searchText, currentPage.current, limit :>> ",
    //   plan,
    //   searchText,
    //   currentPage.current,
    //   limit
    // );
    setIsLoading(true);
    try {
      const response: any = await getAllUser({
        role: "user",
        page: currentPage.current,
        limit: limit,
        plan: plan,
        text: state.searchText.trim(),
      });

      if (response === 0) {
        console.log("Response", response);
        setState((prev: any) => ({
          ...prev,
          noData: true,
          pageCount: 0,
        }));
        setPageItemRange(0, 0);
      } else {
        setState((prev: any) => ({
          ...prev,
          plan: response.plan,
          pageCount: response.pageCount,
          searchText: state.searchText,
          tableData: convertToUsableArray(response?.result[0]?.user),
          noData: false,
        }));

        // setPlan(response.plan);
        // setPageCount(response.pageCount);
        // setSearchText(searchText);
        // //console.log("userlisting",response?.result[0]['user'])
        // setTableData(convertToUsableArray(response?.result[0]?.user));
        // //console.log("TableData Users: ", response.result);
        setPageItemRange(currentPage.current, response.totalUsers);
      }
    } catch (error: any) {
      // console.log("error :>> ", error.response.data.message);
      toaster.errorToast(error.response.data.message);
      setState((prev: any) => ({ ...prev, plan: "all" }));
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlanChange = (e: any) => {
    setState((prevState) => ({
      ...prevState,
      plan: e.value,
    }));
    currentPage.current = 1;
    getUserListing(e.value, state.limit);
  };

  const handlePaginationLimit = (e: any) => {
    setState((prevState) => ({
      ...prevState,
      limit: e.value,
    }));
    currentPage.current = 1;
    getUserListing(state.plan, e.value);
    // console.log("Limit", limit);
  };

  const handleSearchSubmit = async (e: React.FormEvent) => {
    setIsLoading(true);
    //console.log("object  searchText :>> ", searchText);
    // e.preventDefault();
    if (state.searchText.trim() == "") {
      setIsLoading(false);
      return;
    }
    currentPage.current = 1;
    getUserListing(state.plan, state.limit);
    setIsLoading(false);
    //  searchDriverFunction();
  };

  //  useEffect(() => {
  //    if (firstRender.current) {
  //      firstRender.current = false;
  //    } else {
  //      if (searchText.trim() == "") {
  //        setNoData(true);
  //        currentPage.current = 1;
  //       //  getPaginatedDriverData();
  //       getUserListing(plan)
  //      }
  //    }
  //  }, [searchText]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      currentPage.current = 1;
      getUserListing(state.plan, state.limit);
    } else {
      if (state.searchText == "") {
        currentPage.current = 1;
        getUserListing(state.plan, state.limit);
      }
    }
    // console.log("Limit", limit);
  }, [state.searchText, state.limit]);

  return (
    <div>
      <Navbar
        flag={false}
        brandText="Users"
        sideBarUseState={sideBarUseState}
        setSideBarUseState={setSideBarUseState}
        // handleSearch={(e: React.SyntheticEvent<EventTarget>) =>
        //   handleSearchSubmit(e)
        // }
        // setSearchText={setSearchText}
      />
      {/* {!noData ? (
        <Card extra={" m-4 w-full p-4 h-full"}>
          <div className="m-4 text-center">
            <h2 style={{ fontSize: "30px" }}>No Results !</h2>
          </div>
        </Card>
      ) : ( */}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <>
            <div className="mt-4">
              <div>
                <Header
                  title="Users"
                  showAddCategoryButton={false}
                  planOptions={planOptions}
                  handlePlanChange={handlePlanChange}
                  handleSearchSubmit={handleSearchSubmit}
                  searchText={state.searchText}
                  setSearchText={(value) =>
                    setState((prevState) => ({
                      ...prevState,
                      searchText: value,
                    }))
                  }
                  plan={state.plan}
                />
                <Table
                  tableData={state.tableData}
                  columns={columns}
                  noData={state.noData}
                />
              </div>
              <div
                className="mx-2 "
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color: "#5E6278",
                }}
              >
                <h5>
                  {pageItemStartNumber} - {pageItemEndNumber}
                </h5>
                {/* <LimitSelect
                  limit={state.limit}
                  handlePaginationLimit={handlePaginationLimit}
                /> */}
                {/* <Select
                  isSearchable={false}
                  // className="w-400"
                  className="custom-select"
                  options={paginationLimit}
                  onChange={(e: any) => {
                    handlePaginationLimit(e);
                  }}
                  value={paginationLimit.filter(function (option: any) {
                    return option.value === state.limit;
                  })}
                  name="limit"
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided: any) => ({
                      ...provided,
                      zIndex: 9999,
                      position: "absolute",
                    }),
                    option: (provided: any, state: any) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#f2f3f7" : "white", // Change the background color here
                      color: "black", // Change the text color here
                      "&:hover": {
                        backgroundColor: "#f2f3f7", // Change the background color on hover
                      },
                    }),
                    // control: (base: any) => ({
                    //   ...base,
                    //   flexDirection: 'row-reverse',
                    // })
                  }}
                  components={{
                    DropdownIndicator: arrowdown,
                    IndicatorSeparator: () => null,
                    ValueContainer: filter,
                  }}
                /> */}
                <div style={{ marginTop: "1rem" }}>
                  <ReactPaginate
                    breakLabel=" .  .  . "
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={state.pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName="pagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="previous-page-btn"
                    previousLinkClassName="page-link"
                    nextClassName="next-page-btn"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    forcePage={currentPage.current - 1}
                  />
                </div>
              </div>
            </div>
          </>
          <>
            {/* {isOpen && (
              <ChakraProvider>
                <Modal
                  isCentered={true}
                  isOpen={isOpen}
                  onClose={onClose}
                  size="xs"
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader></ModalHeader>
                    <div className="mb-2 flex justify-center">
                      {modalState ? (
                        <img src={deleteIcon} />
                      ) : (
                        <img src={blockIcon} />
                      )}
                    </div>
                    {modalState ? (
                      <ModalBody className="text-center">
                        Are you sure you want to Delete? <br />
                        {'"' + selectedItem.fullName.name + '"'}
                      </ModalBody>
                    ) : (
                      <ModalBody className="text-center">
                        {selectedItem.action.driverStatus == "active"
                          ? "Are you sure you want to Block ?"
                          : "Are you sure you want to Unblock ?"}
                        <br />
                        {'"' + selectedItem.fullName.name + '"'}
                      </ModalBody>
                    )}

                    <div className="mt-3 flex justify-center">
                      {modalState ? (
                        <Button
                          className="cancel-delete-modal-button mx-2"
                          onClick={onClose}
                        >
                          Cancel
                        </Button>
                      ) : (
                        <Button
                          className="cancel-block-modal-button mx-2"
                          onClick={onClose}
                        >
                          Cancel
                        </Button>
                      )}
                      {modalState ? (
                        <Button
                          className="delete-modal-button mx-2"
                          onClick={() => deleteHandle(selectedItem.action)}
                        >
                          Delete
                        </Button>
                      ) : (
                        <Button
                          className="block-modal-button mx-2"
                          onClick={() =>
                            updateDriverStatus(selectedItem.action.id)
                          }
                        >
                          {selectedItem.action.driverStatus == "active"
                            ? "Block"
                            : "Unblock"}
                        </Button>
                      )}
                    </div>
                    <ModalFooter></ModalFooter>
                  </ModalContent>
                </Modal>
              </ChakraProvider>
            )} */}
          </>
        </>
      )}
    </div>
  );
};

export default Users;
const columnHelper = createColumnHelper<RowObj>();
