import React from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import dummyProfile from "../../assets/svg/dummyProfile.svg";

const ImageWithFallback: React.FC<{
  src: string;
  alt: string;
  fallbackSrc: string;
  className?: any;
}> = ({ src, alt, fallbackSrc, className }) => {
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement>) => {
    // This function is called when the original image fails to load.
    // Set the source (src) of the image to the fallback source.
    event.currentTarget.src = fallbackSrc;
  };
  return (
    <img src={src} alt={alt} onError={handleImageError} className={className} />
  );
};

const Navbar = (props: {
  onOpenSidenav?: () => void;
  brandText?: string;
  secondary?: boolean | string;
  handleSearch?: (e: React.SyntheticEvent<EventTarget>) => void;
  setSearchText?: (val: string) => void;
  flag?: boolean;
  sideBarUseState?: boolean;
  setSideBarUseState?: (val: boolean) => void;
}) => {
  const {
    onOpenSidenav,
    brandText,
    handleSearch,
    setSearchText,
    flag,
    sideBarUseState,
    setSideBarUseState,
  } = props;
  const [darkmode, setDarkmode] = React.useState(
    document.body.classList.contains("dark")
  );

  const name = localStorage.getItem("name");
  const key = localStorage.getItem("profileImgKey");

  const handleLogout = () => {
    localStorage.removeItem("profileImgKey");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("name");
  };

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl  dark:bg-[#0b14374d]">
      <div className="ml-[6px]">
        <div className="h-6 w-[224px] pt-1">
          {/* <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            Pages
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "}
            </span>
          </a> */}
          {/* <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link> */}
        </div>
        {/* <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p> */}
      </div>
      <form onSubmit={handleSearch} style={{ display: "inherit" }}>
        <div className="me-3 mt-2 text-right">
          <h6 style={{ color: "#A1A5B7", fontSize: "12", fontWeight: "500" }}>
            Hello
          </h6>
          <h6 style={{ color: "#36364D", fontSize: "12", fontWeight: "600" }}>
            {name}
          </h6>
        </div>
        <div
          className={
            "relative mt-[3px] flex h-[61px] w-[150px] flex-grow items-center justify-around gap-2 rounded-xl  bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[150px] md:flex-grow-0 md:gap-1 xl:w-[60px] xl:gap-2"
          }
        >
          {/* {flag && (
            <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
              <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
              </p>
              <input
                type="text"
                placeholder="Search..."
                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          )} */}
          <span
            className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
            onClick={() => setSideBarUseState(!sideBarUseState)}
          >
            <FiAlignJustify className="h-5 w-5" />
          </span>
          {/* <div
            className="cursor-pointer text-gray-600"
            onClick={() => {
              if (darkmode) {
                document.body.classList.remove("dark");
                setDarkmode(false);
              } else {
                document.body.classList.add("dark");
                setDarkmode(true);
              }
            }}
          >
            {darkmode ? (
              <RiSunFill
                className="h-4 w-4 text-gray-600 dark:text-white"
              />
            ) : (
              <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
            )}
          </div> */}
          {/* Profile & Dropdown */}
          <Dropdown
            button={
              key !== null ? (
                <ImageWithFallback
                  src={key}
                  alt={"profile image"}
                  fallbackSrc={dummyProfile}
                  className="h-9 w-9 rounded-sm"
                />
              ) : (
                // <img className="h-9 w-9 rounded-sm" src={key} alt="profile" />
                <img className="h-9 w-9" src={dummyProfile} alt="profile" />
              )
            }
            children={
              <div className="h-26 flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat py-3 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                <div className="ml-4 mt-3 flex flex-col">
                  <a
                    className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                    href={`/admin/Profile/edit`}
                  >
                    Edit Profile
                  </a>
                </div>
                <div className="ml-4 mt-3 flex flex-col">
                  <a
                    className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                    href="/admin/resetPass"
                  >
                    Reset Password
                  </a>
                </div>
                <div className="ml-4 mt-3 flex flex-col">
                  <a
                    onClick={handleLogout}
                    href="/login"
                    className="text-sm font-medium text-red-500 hover:text-red-500"
                  >
                    Log Out
                  </a>
                </div>
              </div>
            }
            classNames={"py-2 top-8 -left-[180px] w-max"}
          />
        </div>
      </form>
    </nav>
  );
};

export default Navbar;
