import customAxios from "./appservices";

//open
export const handleLoginApi = (data) => {
  return customAxios.post("/admin/login", data);
};

//open
export const handleRegisterApi = (data) => {
  return customAxios.post("/admin/register", data);
};

//superAdmin
export const handleChangePasswordApi = (data) => {
  return customAxios.patch("/admin/change-password", data);
};

//superAdmin
export const getUserDetailsApi = (id) => {
  return customAxios.post("/admin/user-details", { userId: id });
};

//superAdmin
export const getAllUserVideosApi = (id) => {
  return customAxios.post("/admin/all-user-videos", { userId: id });
};

//superAdmin
export const deleteAdminApi = (data) => {
  return customAxios.delete(`/admin/${data}`);
};

// //superAdmin
// export const getPaginatedAdminDataApi = (data) => {
//   return customAxios.post(`/paginatedAdminData`, data);
// };

//both
export const handleCreateAdminApi = (id, data) => {
  return customAxios.patch(`/admin/updateAdmin/${id}`, data);
};

//superAdmin
export const createAdminApi = (data) => {
  return customAxios.post("/admin/create-Admin", data);
};

//both
export const getAdminByIdApi = (id) => {
  //console.log("Params1>>", id);
  return customAxios.get(`/admin/getAdminById/edit`);
};

//both
export const deleteObjectFromS3Api = (data) => {
  return customAxios.post("/videos/delete-object-from-s3", data);
};

//superAdmin
export const getAllUser = (data) => {
  return customAxios.post("/admin/getUsers", data);
};

//superAdmin
export const getAllAdmin = (data) => {
  return customAxios.post("/admin/getAdmins", data);
};

//both
export const handleUpdateCategoryApi = (id, data) => {
  return customAxios.patch(`/categories/${id}`, data);
};

//both
export const createCategoryApi = (data) => {
  return customAxios.post("/categories", data);
};

//both
export const getCategoryByIdApi = (id) => {
  return customAxios.get(`/categories/${id}`);
};

//both
export const getAllCategories = (data) => {
  //console.log("object data :>> ", data);
  return customAxios.post(`/categories/getAllCategories`, data);
};

//both
export const deleteCategoryHandleApi = (info) => {
  //console.log("object info :>> ", info);
  return customAxios.delete(`/categories/${info}`);
};

//both
export const getAllSkills = (data) => {
  //console.log("object data :>> ", data);
  return customAxios.post(`/skill/getAllSkills`, data);
};

//both
export const deleteSkillHandleApi = (info) => {
  //console.log("object info :>> ", info);
  return customAxios.delete(`/skill/${info}`);
};

//both
export const handleUpdateSkillApi = (id, data) => {
  return customAxios.patch(`/skill/${id}`, data);
};

//both
export const createSkillApi = (data) => {
  return customAxios.post("/skill", data);
};

//both
export const getSkillByIdApi = (id) => {
  return customAxios.get(`/skill/${id}`);
};

//both
export const getAllVideos = (data) => {
  return customAxios.post(`/videos/getAllVideos`, data);
};

//both
export const deleteVideoHandleApi = (info) => {
  return customAxios.delete(`/videos/${info}`);
};

//both
export const getS3SignUrlApi = (data, headers) => {
  return customAxios.post(`/videos/preSignedUrl`, data, headers);
};

//both
export const getAllSkillsApi = () => {
  return customAxios.get("/skill/formSkillData");
};

//both
export const getAllCategoryByTagApi = (tag) => {
  return customAxios.post(`/categories/formCategoryData`, tag);
};

//both
export const createVideoApi = (values) => {
  return customAxios.post(`/videos/createVideos`, values);
};

//both
export const getVideoDetailsById = (id) => {
  return customAxios.get(`/videos/${id}`);
};

//both
export const handleUpdateVideoApi = (id, body) => {
  return customAxios.patch(`/videos/updateVideo/${id}`, body);
};

//both
export const getAuthors = () => {
  return customAxios.get(`/admin/getAuthors`);
};

//superAdmin
export const getDashboardDataApi = () => {
  return customAxios.get(`/admin/getDashboardData`);
};

//superAdmin
export const getRevenueDataApi = (data) => {
  return customAxios.post(`/admin/getRevenueData`, data);
};