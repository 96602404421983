import React, { useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import sort from "../../assets/svg/sort.svg";

interface Props {
  tableData: any;
  columns: any;
  header?: boolean;
  rowGap?: boolean;
  noData?: boolean;
}

const Table: React.FC<Props> = ({
  tableData,
  columns,
  header = true,
  rowGap = false,
  noData = false,
}) => {
  const [data, setData] = React.useState([...tableData]);
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    setData([...tableData]);
  }, [tableData]);

  return (
    <div className="mt-4 overflow-x-scroll xl:overflow-x-hidden">
      <table className="w-full rounded-md bg-white pl-2 pr-2">
        {header && (
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pl-4 pr-4 pt-4 text-start"
                    >
                      <div className="flex items-center">
                        <div className="items-center justify-between text-xs text-gray-200">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: "",
                            desc: "",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                        <div className="ml-1 flex h-4 w-4 items-center justify-end">
                          <img style={{ cursor: "pointer" }} src={sort} />
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
        )}
        {noData ? (
          <tbody>
            <tr>
              <td
                colSpan={columns.length}
                style={{ textAlign: "center" }}
                className="rounded-md bg-white"
              >
                <h2 className="m-4" style={{ fontSize: "30px" }}>
                  No Results!
                </h2>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {table.getRowModel().rows.map((row) => {
              // //console.log("object row :>> ", row);
              return (
                <tr key={row.id} className="rounded-2xl">
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className={
                          rowGap
                            ? "min-w-[135px] rounded-2xl border-y-[10px] border-lightPrimary bg-white py-3 pl-4 pr-4 text-start"
                            : "min-w-[135px] rounded-2xl border-white/0 py-3 pl-4 pr-4 text-start"
                        }
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
};
export default Table;
