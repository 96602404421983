import {
  MdArrowDropUp,
  MdOutlineCalendarToday,
  MdBarChart,
} from "react-icons/md";
import Card from "components/card";
import LineChart from "components/charts/LineChart1";

const TotalSpent = (props:any) => {
  const { curYear , prevYear} = props;

  const lineChartOptionsTotalSpent = {
    legend: {
      show: false,
    },

    theme: {
      mode: "light",
    },
    chart: {
      type: "line",

      toolbar: {
        show: false,
      },
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    tooltip: {
      style: {
        fontSize: "12px",
        backgroundColor: "#000000",
      },
      theme: "dark",
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
    grid: {
      show: true,
      yaxis: {
        lines: {
          show: true,
          opacity: 0.3,
        },
      },
      row: {
        opacity: 0.3,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      type: "text",
      categories: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
    },

    yaxis: {
      show: true,
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: true,
      },
    },
  };

  const lineChartDataTotalSpent = [
    {
      name: "Current year",
      data: curYear,
      color: "#49308C",
    },
    {
      name: "Previous year",
      data: prevYear,
      color: "#FF9966",
    },
  ];

  return (
    <Card extra="!p-[20px] text-center shadow-none" background="#F9F7FF">
      <div className="flex justify-between">
        <div className="linear mt-1 flex items-center justify-center gap-2 p-2 text-gray-600 dark:bg-navy-700">
          {/* <MdOutlineCalendarToday /> */}
          <span className="text-[14px] font-bold text-[#1C1C1C]">
            Total Users
          </span>
          <span className="text-sm font-medium text-[#1C1C1C]">|</span>
          <span className="ms-4 text-sm font-normal text-[#1C1C1C]">
            <ul style={{ listStyleType: "disc", color: "#49308C" }}>
              <li>Current Year</li>
            </ul>
          </span>
          <span className="ms-4 text-sm font-normal text-[#1C1C1C]">
            <ul style={{ listStyleType: "disc", color: "#FF9966" }}>
              <li>Previous Year</li>
            </ul>
          </span>
        </div>
        {/* <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
          <MdBarChart className="h-6 w-6" />
        </button> */}
      </div>

      <div className="h-full w-full sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        {/* <div className="flex flex-col">
          <p className="mt-[20px] text-3xl font-bold text-navy-700 dark:text-white">
            $37.5K
          </p>
          <div className="flex flex-col items-start">
            <p className="mt-2 text-sm text-gray-600">Total Spent</p>
            <div className="flex flex-row items-center justify-center">
              <MdArrowDropUp className="font-medium text-green-500" />
              <p className="text-sm font-bold text-green-500"> +2.45% </p>
            </div>
          </div>
        </div> */}
        <div className="h-full w-full">
          <LineChart
            chartOptions={lineChartOptionsTotalSpent}
            chartData={lineChartDataTotalSpent}
          />
        </div>
      </div>
    </Card>
  );
};

export default TotalSpent;
