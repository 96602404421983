import Chart from "react-apexcharts";


type ChartProps = {
  chartData: any[];
  chartOptions: any;
};  

const BarChart1 = ({ chartData, chartOptions }: ChartProps) => {
    
  return (
    <>
      <Chart
        options={chartOptions}
        series={chartData}
        type="bar"
        width="100%"
        height="300px"
      />
    </>
  );
};
export default BarChart1;
