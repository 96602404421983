import Card from "components/card";

const Widget = (props: {
  icon?: JSX.Element;
  title: string;
  subtitle: string;
  subtitleTwo?: string;
  backgroundColor?: string;
}) => {
  const { icon, title, subtitle,subtitleTwo, backgroundColor } = props;
  return (
    <Card
      background={backgroundColor}
      extra="!flex-row items-center rounded-[10px] shadow-none h-[110px]"
    >
      <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center"></div>

      <div className="h-50 ml-4 flex w-auto flex-col justify-center">
        <p className="mb-2 font-dm text-sm font-medium text-[#1C1C1C]">
          {title}
        </p>
        <div className="flex justify-between">
          <div>
            {subtitleTwo && <h5 className="text-[14px]">Total</h5>}
            <h4 className="text-[20px] font-bold text-navy-700 dark:text-white">
              {subtitle}
            </h4>
          </div>
          <div>
            {subtitleTwo && (
              <>
                <h5 className="ml-[28px] text-[14px]">Active</h5>
                <h4 className="ml-[28px] text-[20px] font-bold text-navy-700 dark:text-white">
                  {subtitleTwo}
                </h4>
              </>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Widget;
