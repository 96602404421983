import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Navbar from "../../../../components/navbar";
import Loader from "../../../../components/loader/loader";
import Card from "../../../../components/card";
import { Button } from "@chakra-ui/react";
import Select from "react-select";
import "./categoryForm.css";
import {
  createCategoryApi,
  getCategoryByIdApi,
  handleUpdateCategoryApi,
} from "../../../../services/customAPI";
import { toaster } from "components/common/toaster";

type formvalues = {
  tag: string;
  category: string;
};

const CategoryForm = (props: any) => {
  const { sideBarUseState, setSideBarUseState } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [tagState, setTagState] = useState("");
  const params = useParams();
  const [initialFormValues, setInitialFormValues] = useState<formvalues>({
    tag: "",
    category: "",
  });
  const navigate = useNavigate();
  const tag = [
    { value: "courses", label: "Courses" },
    { value: "upskill", label: "Upskill" },
    { value: "careers", label: "Careers" },
  ];

  const driverSchema = Yup.object().shape({
    tag: Yup.string()
      .min(2, "Tag name must be atleast two characters.")
      .required("Tag is required"),
    category: Yup.string()
      .min(2, "Category must be atleast two characters.")
      .required("category is required")
      .test(
        "no-empty-spaces",
        "Category cannot be empty spaces",
        (value) => value.trim() !== ""
      ),
  });

  const handleCreateCategory = async (values: any) => {
    //console.log("object values :>> ", values);
    try {
      setIsLoading(true);
      if (params.id) {
        const result: any = await handleUpdateCategoryApi(params.id, {
          tag: values.tag,
          category: values.category,
        });

        if (result) {
          toaster.successToast("Category updated Successfully");
          navigate("/admin/categories");
          // window.history.back();
          setIsLoading(false);
        } else {
          toaster.errorToast("Something went wrong");
        }
      } else {
        const result: any = await createCategoryApi({
          tag: values.tag,
          category: values.category,
        });

        //console.log("object result :>> ", result);

        if (result) {
          toaster.successToast("Category Created Successfully");
          navigate("/admin/categories");
          // window.history.back();
          setIsLoading(false);
        } else {
          toaster.errorToast("Something went wrong");
        }
        setIsLoading(false);
      }
    } catch (error: any) {
      toaster.errorToast(error.response.data.message);
      //console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getData = async (id: string) => {
    try {
      setIsLoading(true);
      const res: any = await getCategoryByIdApi(id);

      //console.log("object res :>> ", res);

      setInitialFormValues({
        tag: res?.tag,
        category: res?.category,
      });
      setTagState(res?.tag);
      setIsLoading(false);
    } catch (error: any) {
      toaster.errorToast(error.response.data.message);
      //console.log("error :>> ", error.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (params.id) {
      getData(params.id);
    }
  }, [params.id]);

  return (
    <>
      <Navbar
        flag={false}
        brandText="CategoryForm"
        sideBarUseState={sideBarUseState}
        setSideBarUseState={setSideBarUseState}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <Card extra={"w-full pb-6 p-4 h-full mt-4 pt-10"}>
          <header className="relative flex items-center justify-between ps-[10%] sm:ps-20">
            {params.id ? (
              <div className="text-xl font-bold text-brand-500 dark:text-white">
                Edit Category
              </div>
            ) : (
              <div className="text-xl font-bold text-brand-500 dark:text-white">
                Add Category
              </div>
            )}
          </header>
          <div className="p-6 sm:p-10 sm:pb-5 sm:pe-20 sm:ps-20">
            <Formik
              enableReinitialize={true}
              initialValues={initialFormValues}
              onSubmit={(values) => handleCreateCategory(values)}
              validationSchema={driverSchema}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  {/* <Logger
                    display="hidden"
                    setVehicleName={setVehicleName}
                    setVehicleType={setVehicleType}
                    allAvailableVehicles={allAvailableVehicles}
                    setIsDocuments={setIsDocuments}
                  /> */}

                  <div className="flex flex-col sm:flex-row sm:justify-between">
                    <div className="mb-3 me-6 w-full">
                      <div className="input-container">
                        <Select
                          // style={{ width: "80%" }}
                          isSearchable={false}
                          options={tag}
                          // defaultValue={vehicleType[0]}
                          onChange={(selectedOption) => {
                            setTagState(selectedOption.value);
                            values.tag = selectedOption.value;
                            setFieldValue("tag", selectedOption.value);
                          }}
                          value={tag.filter(function (option: any) {
                            return option.value == tagState;
                          })}
                          name="tag"
                          id="tag"
                          placeholder=""
                          styles={{
                            // placeholder: (provided: any) => ({
                            //   ...provided,
                            //   fontSize: "16px",
                            //   color: "#6F81A5",
                            // }),
                            dropdownIndicator: (base: any) => ({
                              ...base,
                              marginTop: "-24px", // Add padding to the bottom of the dropdown indicator
                            }),
                            // Fixes the overlapping problem of the component
                            menu: (provided: any) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                            control: (provided: any, state: any) => ({
                              ...provided,
                              height: "64px", // Adjust the height as needed
                              paddingTop: "8px",
                              borderColor: "#9ca3af",
                              // This line disable the blue border
                              boxShadow: state.isFocused
                                ? "0 0 0 2px #9ca3af"
                                : "#9ca3af",
                              "&:hover": {
                                // border: state.isFocused ? 0 : 0,
                              },
                            }),
                            option: (provided: any, state: any) => ({
                              ...provided,
                              backgroundColor: state.isSelected
                                ? "#f2f3f7"
                                : "white", // Change the background color here
                              color: "black", // Change the text color here
                              "&:hover": {
                                backgroundColor: "#f2f3f7", // Change the background color on hover
                              },
                            }),
                          }}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                        <label
                          htmlFor="tag"
                          className={`input-custom-label dark:text-white ${
                            values?.tag ? "filled" : ""
                          }`}
                        >
                          Tag
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.tag && touched.tag ? errors.tag : null}
                      </div>
                    </div>
                    <div className="mb-3 w-full sm:ms-6">
                      <div className="input-container">
                        <input
                          required
                          className="w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          name="category"
                          type="text"
                          id="category"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.category}
                        />
                        <label
                          htmlFor="category"
                          className={`input-custom-label dark:text-white ${
                            values?.category ? "filled" : ""
                          }`}
                        >
                          Category
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.category && touched.category
                          ? errors.category
                          : null}
                      </div>
                    </div>
                  </div>

                  <div className="button-save-cancel mt-3 flex justify-end">
                    <Button
                      className=" cancel-button my-2 ms-1 sm:my-0"
                      onClick={() => {
                        // navigate("/admin/categories")
                        window.history.back();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      className="save-button my-2 ms-1 bg-brand-500 dark:bg-brand-400 sm:my-0"
                    >
                      Save
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Card>
      )}
    </>
  );
};

export default CategoryForm;
