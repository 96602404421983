import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";

import Widget from "components/widget/Widget";
import Card from "../../../components/card";
import Navbar from "../../../components/navbar";
import Loader from "../../../components/loader/loader";
import { useEffect, useState } from "react";
import {
  getDashboardDataApi,
  getRevenueDataApi,
} from "../../../services/customAPI";
import CalendarSvg from "../../../assets/svg/week.svg";
import { toaster } from "../../../components/common/toaster";

const Dashboard1 = (props: any) => {
  const { sideBarUseState, setSideBarUseState } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isDateRangeVisible, setIsDateRangeVisible] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    totalRevenue: null,
    totalViews: null,
    totalVideos: null,
    totalUsers: null,
    totalActiveUsers: null,
    weekRevenueValue: [],
    weekRevenueLabel: [],
    totalUsersForGraphCurYear: [],
    totalUsersForGraphPrevYear: [],
  });
  const [state, setState] = useState([
    {
      startDate: addDays(new Date(), -6),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const currentDate = new Date(); // Current date
  currentDate.setHours(0);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);

  const getDashboardData = async () => {
    try {
      setIsLoading(true);
      const resp: any = await getDashboardDataApi();

      // console.log("object resp :>> ", resp);

      if (resp.code === 200) {
        setDashboardData((prev) => ({
          ...prev,
          totalRevenue: resp.data.totalRevenue,
          totalViews: resp.data.totalViews,
          totalVideos: resp.data.totalVideos,
          totalUsers: resp.data.totalUsers.total,
          totalActiveUsers: resp.data.totalUsers.active,
          weekRevenueValue: resp.data.weekRevenue.weekRevenueValue,
          weekRevenueLabel: resp.data.weekRevenue.weekRevenueLabel,
          totalUsersForGraphCurYear: resp.data.totalUsersForGraph.currYear,
          totalUsersForGraphPrevYear: resp.data.totalUsersForGraph.prevYear,
        }));
      } else {
        throw Error("Sonething went wrong");
      }
    } catch (error) {
      console.log("error :>> ", error);
      toaster.errorToast("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelect = (ranges: any) => {
    if (ranges.selection.endDate > currentDate) {
      ranges.selection.endDate = currentDate;
    } 

    setState([ranges.selection]);
  };

  const handleCancel = () => {
    setIsDateRangeVisible(false);
  };


  function addTimeToDate(date: any) {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + 23);
    newDate.setMinutes(newDate.getMinutes() + 59);
    newDate.setSeconds(newDate.getSeconds() + 59);
    return newDate;
  }

  const handleApply = async () => {
    // actual logic here
    try {
      setIsDateRangeVisible(false);
      setIsLoading(true);
      const start = state[0].startDate;
      const end = addTimeToDate(state[0].endDate);

      const resp: any = await getRevenueDataApi({ start, end });

      if (resp.code === 200) {
        setDashboardData((prev) => ({
          ...prev,
          weekRevenueValue: resp.data.revenue.RevenueValue,
          weekRevenueLabel: resp.data.revenue.RevenueLabel,
        }));
      } else {
        throw Error("Sonething went wrong");
      }
    } catch (error) {
      console.log("error :>> ", error);
      toaster.errorToast("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <>
      <Navbar
        brandText="Dashboard"
        sideBarUseState={sideBarUseState}
        setSideBarUseState={setSideBarUseState}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <Card extra={"w-full pb-6 p-5 h-full mt-4 pt-5"}>
          <div className="mb-2 text-[24px] font-bold text-[#49308C]">
            Dashboard
          </div>
          <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 3xl:grid-cols-4">
            <Widget
              backgroundColor="#FFEBE0"
              title={"Total Revenue"}
              subtitle={`Rs. ${dashboardData.totalRevenue}`}
            />
            <Widget
              backgroundColor="#F2EFFA"
              title={"Views"}
              subtitle={dashboardData.totalViews}
            />
            <Widget
              backgroundColor="#FFEBE0"
              title={"Videos"}
              subtitle={dashboardData.totalVideos}
            />
            <Widget
              backgroundColor="#F2EFFA"
              title={"Users"}
              subtitle={dashboardData.totalUsers}
              subtitleTwo={dashboardData.totalActiveUsers}
            />
          </div>
          <div className="mt-2 flex justify-between">
            <div className="mt-3 text-[20px] font-bold text-[#3F4254]">
              Revenue
            </div>
            <div className="mt-3">
              {isDateRangeVisible ? (
                <>
                  <div
                    style={{ position: "absolute", zIndex: 10, right: "20px" }}
                  >
                    <DateRangePicker
                      ranges={state}
                      onChange={handleSelect}
                      showDateDisplay={true}
                      maxDate={currentDate}
                      months={1}
                    />

                    <div className="flex justify-end bg-white">
                      <button
                        className="me-4 hover:text-[#8c8c8c]"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button
                        className="hover:text-[#8c8c8c]"
                        onClick={handleApply}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  <button
                    className="rounded-[5px] bg-[#FFEBE0] px-2 py-1 text-[#7E8299]"
                    onClick={() => setIsDateRangeVisible(true)}
                  >
                    <img
                      className="me-3 inline-block"
                      src={CalendarSvg}
                      alt="img"
                      height="8%"
                      width="8%"
                    />
                    {state[0]?.startDate
                      ? state[0]?.startDate.toLocaleDateString()
                      : "YYYY-MM-DD"}{" "}
                    to{" "}
                    {state[0]?.endDate
                      ? state[0]?.endDate.toLocaleDateString()
                      : "YYYY-MM-DD"}
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-1">
            <WeeklyRevenue
              data={dashboardData.weekRevenueValue}
              label={dashboardData.weekRevenueLabel}
            />
          </div>
          <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-1">
            <TotalSpent
              curYear={dashboardData.totalUsersForGraphCurYear}
              prevYear={dashboardData.totalUsersForGraphPrevYear}
            />
          </div>
        </Card>
      )}
    </>
  );
};

export default Dashboard1;
