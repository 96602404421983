import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import Loader from "components/loader/loader";
import { handleChangePasswordApi } from "services/customAPI";
import { toaster } from "components/common/toaster";
import Navbar from "components/navbar";
import Card from "components/card";
import { Button } from "@chakra-ui/react";
import "../skills/skillForm/skillForm.css";

export type resetPassword = {
  email: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const ResetPass: React.FC = (props: any) => {
  const { sideBarUseState, setSideBarUseState } = props;
  let navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const changePasswordSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Invalid email"),
    oldPassword: Yup.string()
      .required("Old password is required")
      .matches(/^\S*$/, "Password should not contain spaces") // Custom validation for no spaces
      .min(8, "Too short !"),
    newPassword: Yup.string()
      .required("New Password is required")
      .min(8, "Too short !")
      .matches(
        /^(?=.*[A-Z])/,
        "Password should contain atleast a uppercase letter"
      )
      .matches(
        /(?=.*[!@#$%^&*])/,
        "Password should contain atleast a special character."
      )
      .matches(/^\S*$/, "Password should not contain spaces") // Custom validation for no spaces
      .notOneOf(
        [Yup.ref("oldPassword")],
        "Try other password! Old password should not match with new Password"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword")], "New Password must match")
      .required("Please confirm password"),
  });

  const handleChangePassword = async (formValues: resetPassword) => {
    try {
      setIsLoading(true);
      const changePasswordRes: any = await handleChangePasswordApi({
        ...formValues,
      });
      // //console.log(`changePasswordRes :>> `, changePasswordRes)
      if (changePasswordRes.message) {
        //console.log("changePass", changePasswordRes.message);
        toaster.successToast("Password changed successfully !");
        // dispatch(setToken(changePasswordRes.data.token));
        navigate("/");
      } else {
        toaster.errorToast("Please enter your credentials correctly");
      }
    } catch (error: any) {
      toaster.errorToast(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  return (
    <div>
      <Navbar
        flag={true}
        brandText="Vehicles"
        sideBarUseState={sideBarUseState}
        setSideBarUseState={setSideBarUseState}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <Card extra={"w-full pb-6 p-4 h-full mt-4 pt-10"}>
          <header className="relative flex items-center justify-between ps-20">
            <div className="text-xl font-bold text-navy-700 dark:text-white">
              Change Password
            </div>
          </header>
          <div className="p-10 pb-5 pe-20 ps-20">
            <Formik
              enableReinitialize={true}
              initialValues={{
                email: "",
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
              }}
              onSubmit={(values) => handleChangePassword(values)}
              validationSchema={changePasswordSchema}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
              }) => (
                <form about="form" onSubmit={handleSubmit}>
                  <div className="mt-3 flex justify-between">
                    <div className="mb-3 me-6 w-full">
                      <div className="input-container">
                        <input
                          required
                          className="w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          name="email"
                          type="text"
                          id="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.email}
                        />
                        <label
                          htmlFor="email"
                          className={`input-custom-label dark:text-white ${
                            values?.email ? "filled" : ""
                          }`}
                        >
                          Email
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.email && touched.email ? errors.email : null}
                      </div>
                    </div>
                    <div className="mb-3 ms-6 w-full">
                      <div className="input-container">
                        <input
                          required
                          className="h-12 w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          name="oldPassword"
                          type="password"
                          id="oldPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.oldPassword}
                        />
                        <label
                          htmlFor="lastName"
                          className={`input-custom-label dark:text-white ${
                            values?.oldPassword ? "filled" : ""
                          }`}
                        >
                          Old Password
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.oldPassword && touched.oldPassword
                          ? errors.oldPassword
                          : null}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="mb-3 me-6 w-full">
                      <div className="input-container">
                        <input
                          required
                          className="h-12 w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          name="newPassword"
                          type="password"
                          id="newPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.newPassword}
                        />
                        <label
                          htmlFor="newPassword"
                          className={`input-custom-label dark:text-white ${
                            values?.newPassword ? "filled" : ""
                          }`}
                        >
                          New Password
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.newPassword && touched.newPassword
                          ? errors.newPassword
                          : null}
                      </div>
                    </div>
                    <div className="mb-3 ms-6 w-full">
                      <div className="input-container">
                        <input
                          required
                          className="h-12 w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          name="confirmPassword"
                          type="password"
                          id="confirmPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.confirmPassword}
                        />
                        <label
                          htmlFor="confirmPassword"
                          className={`input-custom-label dark:text-white ${
                            values?.newPassword ? "filled" : ""
                          }`}
                        >
                          Confirm Password
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.confirmPassword && touched.confirmPassword
                          ? errors.confirmPassword
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="button-save-cancel mt-3 flex justify-end">
                    <Button
                      className=" cancel-button my-2 ms-1 sm:my-0"
                      onClick={() => {
                        // navigate("/admin/Skills")
                        window.history.back();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      className="save-button my-2 ms-1 !w-[175px] bg-brand-500 dark:bg-brand-400 sm:my-0"
                    >
                      Change Password
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Card>
      )}
    </div>
  );
};
export default ResetPass;
