import React, { useRef, useState } from "react";
import Card from "../../../../components/card";
import Select from "react-select";
import { Button } from "@chakra-ui/react";
import "./adminform.css";
import { ErrorMessage, Formik, useFormikContext } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
// import {

// } from "../../../../services/customAPI";
import Loader from "components/loader/loader";
import Navbar from "../../../../components/navbar";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import pdf from "../../../../assets/svg/pdf.svg";
import cross from "../../../../assets/svg/cross.svg";
import uploadCloud from "../../../../assets/svg/upload-cloud.svg";
import profilePlaceholder from "../../../../assets/svg/profilePlaceholder.svg";
import {
  createAdminApi,
  deleteObjectFromS3Api,
  getAdminByIdApi,
  getS3SignUrlApi,
  handleCreateAdminApi,
} from "services/customAPI";
import { toaster } from "components/common/toaster";

type profImage = {
  key: string;
  url: string;
  file?: any;
};

type formvalues = {
  name: string;
  lastName: string;
  email: string;
  password?: string;
  image?: any;
};

const AdminForm = (props: any) => {
  const { sideBarUseState, setSideBarUseState } = props;
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState<formvalues>({
    name: "",
    lastName: "",
    email: "",
    ...(params.id ? {} : { password: "" }), // Include password if params.id does not exist
    ...(params.id ? { image: "" } : {}),
  });

  const [imageFile, setImageFile] = useState(null);
  const anchorImageRef = useRef(null);

  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);
  const [initialProfileImage, setInitialProfileImage] = useState<profImage>();
  const [finalProfileImage, setFinalProfileImage] = useState<profImage>();
  const [isProfileImage, setIsProfileImage] = useState(
    params.id ? false : true
  );
  const [isValidation, setIsValidation] = useState({
    thumbnail: !params,
  });

  const FILE_SIZE = 1024 * 1024;
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  const adminSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "First name must be atleast two characters.")
      .required("First name is required.")
      .test(
        "no-empty-spaces",
        "last name cannot have empty spaces",
        (value) => value.trim() !== ""
      ),
    lastName: Yup.string()
      .min(2, "Last name must be atleast two characters.")
      .required("Last name is required.")
      .test(
        "no-empty-spaces",
        "last name cannot have empty spaces",
        (value) => value.trim() !== ""
      ),
    email: Yup.string()
      .email("Invalid email.")
      .required("Email is required.")
      .test(
        "no-empty-spaces",
        "email cannot have empty spaces",
        (value) => value.trim() !== ""
      ),
    password: params.id
      ? Yup.string() // When params.id exists, the password is not required
      : Yup.string()
          .required("Password is required,")
          .min(8, "Password must be atleast 8 characters.")
          .matches(
            /^(?=.*[A-Z])/,
            "Password should contain atleast one uppercase letter"
          )
          .matches(
            /(?=.*[!@#$%^&*])/,
            "Password should contain atleast one special character."
          )
          .test(
            "no-empty-spaces",
            "Password cannot have empty spaces",
            (value) => value.trim() !== ""
          ),
    // password: Yup.string()
    //   .required("Password is required,")
    //   .min(8, "Password must be atleast 8 characters.")
    //   .matches(
    //     /^(?=.*[A-Z])/,
    //     "Password should contain atleast a uppercase letter"
    //   )
    //   .matches(
    //     /(?=.*[!@#$%^&*])/,
    //     "Password should contain atleast a special character."
    //   ),
    image: isValidation.thumbnail
      ? Yup.mixed()
          // .nullable()
          .required("A file is required")
          .test("fileSize", "File too large", (value: any) => {
            return value && value.size <= FILE_SIZE;
          })
          .test(
            "fileFormat",
            "Unsupported Format",
            (value: any) => value && SUPPORTED_FORMATS.includes(value.type)
          )
      : Yup.mixed().notRequired(),
  });

  async function getS3SignUrl(key: string, contentType: string, type: string) {
    const headers = { "Content-Type": "application/json" };
    const response: any = await getS3SignUrlApi(
      {
        key,
        contentType,
        type,
      },
      { headers }
    );
    return response;
  }

  async function pushProfilePhotoToS3(presignedUrl: string, uploadPhoto: any) {
    console.log("pushProfilePhotoToS3", uploadPhoto);
    console.log("pushProfileURL", presignedUrl);
    const response = await axios.put(presignedUrl, uploadPhoto);
    console.log("pushProfilePhotoToS3  res:>> ", response);
    return response;
  }

  const handleCreateAdmin = async (values: any) => {
    //console.log("createAdmin", values);
    setIsLoading(true);
    try {
      if (params.id) {
        let res;
        if (finalProfileImage.url === "") {
          // //console.log("image key to upload :>> ", finalProfileImage.url);
          {
            const key = finalProfileImage.key;
            // console.log("image key to upload :>> ", key);
            const contentType = "image/*";
            const type = "put";
            const data: any = await getS3SignUrl(key, contentType, type);
            // console.log("imageUrl :>> ", data);
            if (data) {
              res = await pushProfilePhotoToS3(data, finalProfileImage.file);
              if (res.status === 200) {
                const reader = new FileReader();
                reader.readAsDataURL(finalProfileImage.file);

                reader.onload = function () {
                  // Get the Base64 data
                  const base64Data = reader.result as string;

                  // Set the Base64 data in local storage
                  localStorage.setItem("profileImgKey", base64Data);
                };
              }
              if (initialProfileImage) {
                // console.log("initialProfileImage", initialProfileImage);
                const response = deleteObjectFromS3Api({
                  key: initialProfileImage,
                });
              }
            }
          }
        }
        //console.log("image key db:>> ", finalProfileImage.key);

        const result: any = await handleCreateAdminApi(params.id, {
          name: values.name,
          lastName: values.lastName,
          email: values.email,
          profileImageKey: finalProfileImage.key,
        });
        // console.log("handleCreateAdmin", result);
        if (result) {
          localStorage.setItem("name", values.name);
          toaster.successToast("Admin Updated Successfully");
          navigate("/");
          // window.history.back();
          setIsLoading(false);
        } else {
          toaster.errorToast("Something went wrong");
        }
      } else {
        const result: any = await createAdminApi({
          name: values.name,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
          profileImageKey: "",
        });
        //console.log("handleCreateAdmin1", result);

        if (result.message) {
          toaster.successToast("Admin Created Successfully");
          navigate("/admin/admins");
          // window.history.back();
          setIsLoading(false);
        } else {
          toaster.errorToast("Something went wrong");
        }
        setIsLoading(false);
      }
    } catch (error: any) {
      toaster.errorToast(error.response.data.message);
      //console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getData = async (id: string) => {
    //console.log("get data called :>> ");
    setIsLoading(true);
    try {
      // const token = localStorage.getItem("token");
      // console.log("adminFormToken", token);
      const res: any = await getAdminByIdApi(id);
      //console.log("get data called", res);
      const key = res?.profileImageKey;
      if (key) {
        {
          setInitialProfileImage(res?.originalKey);
          setFinalProfileImage({ key: res?.originalKey, url: key });
          setImagePreview(key);
        }
      }

      setInitialFormValues({
        name: res.name,
        lastName: res.lastName,
        email: res.email,
        // password: res.password,
        image: key,
      });

      setIsLoading(false);
    } catch (error: any) {
      toaster.errorToast(error.response.data.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDivClickImg = () => {
    if (anchorImageRef.current) {
      anchorImageRef.current.click();
    }
  };

  React.useEffect(() => {
    if (params.id) {
      getData(params.id);
    }
  }, [params.id]);

  return (
    <>
      <Navbar
        flag={false}
        brandText="adminform"
        sideBarUseState={sideBarUseState}
        setSideBarUseState={setSideBarUseState}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <Card extra={"w-full pb-6 p-4 h-full mt-4 pt-10"}>
          <header className="relative flex items-center justify-between ps-[10%] sm:ps-20">
            {params.id ? (
              <div className="text-xl font-bold text-brand-500 dark:text-white">
                Edit Admin
              </div>
            ) : (
              <div className="text-xl font-bold text-brand-500 dark:text-white">
                Add Admin
              </div>
            )}
          </header>
          <div className="p-6 sm:p-10 sm:pb-5 sm:pe-20 sm:ps-20">
            <Formik
              enableReinitialize={true}
              initialValues={initialFormValues}
              onSubmit={(values) => handleCreateAdmin(values)}
              validationSchema={adminSchema}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  {params.id ? (
                    <div className="flex justify-between">
                      <div className="mb-3 w-full sm:me-6">
                        <div className="mt-4">
                          <div
                            style={{
                              border: "1px solid #9CA3AF",
                              borderRadius: "4px",
                              cursor: "pointer",
                            }}
                            className="h-20 rounded-xl border bg-white/0 text-sm outline-none"
                          >
                            <div
                              className="flex h-full items-center justify-center"
                              style={{ cursor: "pointer" }}
                            >
                              <div className="">
                                {imagePreview ? (
                                  <>
                                    <div
                                      className="image-preview"
                                      style={{
                                        width: "55px",
                                        height: "55px",
                                        padding: "2px",
                                        border: "2px solid #9CA3AF",
                                        borderRadius: "4px",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <img
                                        src={imagePreview}
                                        style={{
                                          objectFit: "contain",
                                          height: "100%",
                                          width: "auto",
                                          cursor: "pointer",
                                          // padding: "5px",
                                        }}
                                        alt="img"
                                        onClick={handleDivClickImg}
                                      />
                                      <a
                                        ref={anchorImageRef}
                                        href={imagePreview}
                                        download="your-image-file.png"
                                        style={{ display: "none" }}
                                      ></a>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="image-preview"
                                      style={{
                                        width: "55px",
                                        height: "55px",
                                        padding: "2px",
                                        borderRadius: "4px",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <img
                                        src={profilePlaceholder}
                                        style={{
                                          objectFit: "contain",
                                          height: "100%",
                                          width: "auto",
                                          cursor: "pointer",
                                          // padding: "5px",
                                        }}
                                        alt="img"
                                        onClick={handleDivClickImg}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                              <label className="flex h-full w-full cursor-pointer items-center justify-center">
                                <div className="flex items-center justify-center">
                                  <div className="">
                                    <img
                                      src={uploadCloud}
                                      alt="Upload Cloud"
                                      height="24px"
                                      width="24px"
                                      className="mr-2"
                                    />
                                  </div>
                                  <div className="text-center">
                                    {!params.id ? (
                                      <>
                                        <a style={{ color: "#FF9966" }}>
                                          Click here {""}
                                        </a>
                                        to upload your image
                                      </>
                                    ) : (
                                      <>
                                        <a style={{ color: "#FF9966" }}>
                                          Click here {""}
                                        </a>
                                        to change your image
                                      </>
                                    )}
                                  </div>
                                </div>
                                <input
                                  // required
                                  accept="image/*"
                                  style={{
                                    backgroundColor: "rgba(242, 242, 242, 0.5)",
                                    display: "none",
                                  }}
                                  className="mt-2 h-12 w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                                  name="image"
                                  type="file"
                                  id="image"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "image",
                                      event.target.files[0]
                                    );
                                    const file = event.target.files[0];
                                    setFinalProfileImage({
                                      key: `admin/profileImages/${uuidv4()}.png`,
                                      url: "",
                                      file: file,
                                    });
                                    if (file) {
                                      const reader = new FileReader();
                                      reader.onload = (e) => {
                                        setImagePreview(e.target.result);
                                      };
                                      reader.readAsDataURL(file);
                                    } else {
                                      setImagePreview(null);
                                    }
                                    if (event.target.files[0]) {
                                      setIsProfileImage(true);
                                    }
                                    if (event.target.files[0]) {
                                      setIsValidation((prev: any) => ({
                                        ...prev,
                                        thumbnail: true,
                                      }));
                                    }
                                  }}
                                  onBlur={handleBlur}
                                />
                              </label>
                            </div>
                          </div>
                          <ErrorMessage
                            name="image"
                            component="div"
                            className="error-input"
                          />
                        </div>
                      </div>
                      <div className="sm:mb-3 sm:ms-6 sm:w-full"></div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="sm:mt-3 flex flex-col sm:flex-row justify-between">
                    <div className="w-full sm:mb-3 sm:me-6">
                      <div className="input-container">
                        <input
                          required
                          className="w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          name="name"
                          type="text"
                          id="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.name}
                        />
                        <label
                          htmlFor="name"
                          className={`input-custom-label dark:text-white ${
                            values?.name ? "filled" : ""
                          }`}
                        >
                          First Name
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.name && touched.name ? errors.name : null}
                      </div>
                    </div>
                    <div className="sm:mb-3 sm:ms-6 w-full">
                      <div className="input-container">
                        <input
                          required
                          className="h-12 w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          name="lastName"
                          type="text"
                          id="lastName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.lastName}
                        />
                        <label
                          htmlFor="lastName"
                          className={`input-custom-label dark:text-white ${
                            values?.lastName ? "filled" : ""
                          }`}
                        >
                          Last Name
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.lastName && touched.lastName
                          ? errors.lastName
                          : null}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row justify-between">
                    <div className="sm:mb-3 sm:me-6 w-full">
                      <div className="input-container">
                        <input
                          required
                          className="h-12 w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          name="email"
                          type="text"
                          id="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.email}
                          disabled={params.id ? true : false}
                        />
                        <label
                          htmlFor="email"
                          className={`input-custom-label dark:text-white ${
                            values?.email ? "filled" : ""
                          }`}
                        >
                          Email
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.email && touched.email ? errors.email : null}
                      </div>
                    </div>
                    <div className="sm:mb-3 sm:ms-6 w-full">
                      {params.id ? null : (
                        <>
                          <div className="input-container">
                            <input
                              required
                              className="h-12 w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                              name="password"
                              type="password"
                              id="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.password}
                            />
                            <label
                              htmlFor="password"
                              className={`input-custom-label dark:text-white ${
                                values?.password ? "filled" : ""
                              }`}
                            >
                              Password
                            </label>
                          </div>
                          <div className="error-input">
                            {errors.password && touched.password
                              ? errors.password
                              : null}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="button-save-cancel mt-3 flex justify-end">
                    <Button
                      className=" cancel-button my-2 ms-1 sm:my-0"
                      onClick={() => {
                        // params.id ? navigate("/") : navigate("/admin/admins");
                        window.history.back();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      className="save-button my-2 ms-1 bg-brand-500 dark:bg-brand-400 sm:my-0"
                    >
                      Save
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Card>
      )}
    </>
  );
};

export default AdminForm;
