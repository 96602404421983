// Icon Imports
import { MdHome } from "react-icons/md";
// import Dashboard from "views/admin/default";
import Users from "views/admin/users";
import Categories from "views/admin/category";
import CategoryForm from "views/admin/category/categoryFrom";
import AdminForm from "views/admin/admin/adminForm";
import Admins from "views/admin/admin";
import Skill from "views/admin/skills";
import SkillForm from "views/admin/skills/skillForm";
import Video from "views/admin/videos";
import VideoForm from "views/admin/videos/videoForm";
import UserDetails from "views/admin/users/userDetails";
import Dashboard from "./views/admin/dashboard";
import ResetPass from "views/admin/resetPass/resetPass";

const routes = [
  {
    name: "Video",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <Video />,
  },
  {
    name: "Reset Password",
    layout: "/admin",
    path: "resetPass",
    icon: <MdHome className="h-6 w-6" />,
    component: <ResetPass />,
    secondary: true,
  },
  // {
  //   name: "Users",
  //   layout: "/admin",
  //   path: "users",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <Users />,
  // },
  // {
  //   name: "Users",
  //   layout: "/admin",
  //   path: "users/userDetails/:id",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <UserDetails />,
  //   secondary: true,
  // },
  {
    name: "Categories",
    layout: "/admin",
    path: "categories",
    icon: <MdHome className="h-6 w-6" />,
    component: <Categories />,
  },
  {
    name: "Skills",
    layout: "/admin",
    path: "Skills",
    icon: <MdHome className="h-6 w-6" />,
    component: <Skill />,
  },
  // {
  //   name: "Videos",
  //   layout: "/admin",
  //   path: "Videos",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <Video />,
  // },
  {
    name: "VideoForm",
    layout: "/admin",
    path: "Videos/videoForm",
    icon: <MdHome className="h-6 w-6" />,
    component: <VideoForm />,
    secondary: true,
  },
  {
    name: "VideoForm",
    layout: "/admin",
    path: "Videos/VideoForm/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <VideoForm />,
    secondary: true,
  },
  {
    name: "CategoryForm",
    layout: "/admin",
    path: "categories/CategoryForm",
    icon: <MdHome className="h-6 w-6" />,
    component: <CategoryForm />,
    secondary: true,
  },
  {
    name: "CategoryForm",
    layout: "/admin",
    path: "categories/CategoryForm/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <CategoryForm />,
    secondary: true,
  },
  // {
  //   name: "Admin",
  //   layout: "/admin",
  //   path: "admins",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <Admins />,
  // },
  // {
  //   name: "AdminForm",
  //   layout: "/admin",
  //   path: "admins/AdminForm",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <AdminForm />,
  //   secondary: true,
  // },
  {
    name: "AdminForm",
    layout: "/admin",
    path: "Profile/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <AdminForm />,
    secondary: true,
  },
  {
    name: "SkillForm",
    layout: "/admin",
    path: "Skills/SkillForm",
    icon: <MdHome className="h-6 w-6" />,
    component: <SkillForm />,
    secondary: true,
  },
  {
    name: "SkillForm",
    layout: "/admin",
    path: "Skills/SkillForm/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <SkillForm />,
    secondary: true,
  },
];
export default routes;
