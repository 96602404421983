import Card from "components/card";
import BarChart from "components/charts/BarChart";
import BarChart1 from "../../../../components/charts/BarChart1";
// import {
//   barChartDataWeeklyRevenue,
//   barChartOptionsWeeklyRevenue,
// } from "variables/charts";
// import { MdBarChart } from "react-icons/md";


const WeeklyRevenue = (props:any) => {
 const { data, label } = props;
//  console.log("data :>> ", data);
//  console.log("label :>> ", label);
  const barChartDataWeeklyRevenue = [
    {
      name: "Revenue",
      data: data,
      color: "#49308C",
    },
  ];

  const barChartOptionsWeeklyRevenue = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    // colors:['#ff3322','#faf']
    tooltip: {
      style: {
        fontSize: "12px",
        backgroundColor: "#000000",
      },
      theme: "dark",
      onDatasetHover: {
        style: {
          fontSize: "12px",
        },
      },
    },
    xaxis: {
      categories: label,
      show: false,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: true,
      },
    },

    grid: {
      borderColor: "rgba(163, 174, 208, 0.3)",
      show: true,
      yaxis: {
        lines: {
          show: true,
          opacity: 0.3,
        },
      },
      row: {
        opacity: 0.3,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "solid",
      colors: ["#49308C"],
    },
    legend: {
      show: false,
    },
    colors: ["#49308C"],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        columnWidth: "10px",
        // color: "#49308C",
      },
    },
  };

  // console.log("barChartDataWeeklyRevenue :>> ", barChartDataWeeklyRevenue);
  // console.log(
  //   "barChartOptionsWeeklyRevenue :>> ",
  //   barChartOptionsWeeklyRevenue
  // );

  
  return (
    <Card extra="!p-[20px] flex flex-col bg-white w-full rounded-3xl text-center shadow-none">
      {/* <div className="mb-auto flex items-center justify-between px-6">
        <h2 className="text-lg font-bold text-navy-700 dark:text-white">
          Weekly Revenue
        </h2>
        <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
          <MdBarChart className="h-6 w-6" />
        </button>
      </div> */}
      <div className="h-full w-full">
        <BarChart1
          chartData={barChartDataWeeklyRevenue}
          chartOptions={barChartOptionsWeeklyRevenue}
        />
      </div>
    </Card>
  );
};

export default WeeklyRevenue;
