import ReactApexChart from "react-apexcharts";



type ChartProps = {
  chartData: any[];
  chartOptions: any;
};  

const LineChart1 = ({ chartData, chartOptions }: ChartProps) => {
    
  return (
    <>
      <ReactApexChart
        options={chartOptions}
        series={chartData}
        type="line"
        width="100%"
        height="300px"
      />
    </>
  );
};
export default LineChart1;
