import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Navbar from "../../../../components/navbar";
import Loader from "../../../../components/loader/loader";
import Card from "../../../../components/card";
import { Button } from "@chakra-ui/react";
import "./skillForm.css";
import {
  handleUpdateSkillApi,
  createSkillApi,
  getSkillByIdApi,
} from "../../../../services/customAPI";
import { toaster } from "components/common/toaster";

type formvalues = {
  label: string;
};

const SkillForm = (props: any) => {
  const { sideBarUseState, setSideBarUseState } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [tagState, setTagState] = useState("");
  const params = useParams();
  const [initialFormValues, setInitialFormValues] = useState<formvalues>({
    label: "",
  });
  const navigate = useNavigate();
  //   const tag = [
  //     { value: "", label: "Select a Tag", disabled: true },
  //     { value: "courses", label: "Courses" },
  //     { value: "upskill", label: "Upskill" },
  //     { value: "careers", label: "Careers" },
  //   ];

  const skillSchema = Yup.object().shape({
    label: Yup.string()
      .min(2, "Skill must be atleast two characters.")
      .required("skill is required")
      .test(
        "no-empty-spaces",
        "Skill cannot be empty spaces",
        (value) => value.trim() !== ""
      ),
  });

  const handleSkillCategory = async (values: any) => {
    //console.log("object values :>> ", values);
    setIsLoading(true);
    try {
      if (params.id) {
        const result: any = await handleUpdateSkillApi(params.id, {
          label: values.label,
          value: values.label.toLowerCase().replace(/ /g, "_"),
        });

        if (result) {
          toaster.successToast("Skill updated Successfully");
          navigate("/admin/Skills");
          // window.history.back();
          setIsLoading(false);
        } else {
          toaster.errorToast("Something went wrong");
        }
      } else {
        const result: any = await createSkillApi({
          label: values.label,
          value: values.label.toLowerCase().replace(/ /g, "_"),
        });

        //console.log("object result :>> ", result);

        if (result) {
          toaster.successToast("Skill Created Successfully");
          navigate("/admin/Skills");
          // window.history.back();
          setIsLoading(false);
        } else {
          toaster.errorToast("Something went wrong");
        }
      }
    } catch (error: any) {
      toaster.errorToast(error.response.data.message);
      //console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getData = async (id: string) => {
    setIsLoading(true);
    try {
      const res: any = await getSkillByIdApi(id);

      //console.log("object res :>> ", res);

      setInitialFormValues({
        label: res?.label,
      });
      setIsLoading(false);
    } catch (error: any) {
      toaster.errorToast(error.response.data.message);
      //console.log("error :>> ", error.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (params.id) {
      getData(params.id);
    }
  }, [params.id]);

  return (
    <>
      <Navbar
        flag={false}
        brandText="SkillForm"
        sideBarUseState={sideBarUseState}
        setSideBarUseState={setSideBarUseState}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <Card extra={"w-full pb-6 p-4 h-full mt-4 pt-10"}>
          <header className="relative flex items-center justify-between ps-[10%] sm:ps-20">
            {params.id ? (
              <div className="text-xl font-bold text-brand-500 dark:text-white">
                Edit Skill
              </div>
            ) : (
              <div className="text-xl font-bold text-brand-500 dark:text-white">
                Add Skill
              </div>
            )}
          </header>
          <div className="p-6 sm:p-10 sm:pb-5 sm:pe-20 sm:ps-20">
            <Formik
              enableReinitialize={true}
              initialValues={initialFormValues}
              onSubmit={(values) => handleSkillCategory(values)}
              validationSchema={skillSchema}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  {/* <Logger
                    display="hidden"
                    setVehicleName={setVehicleName}
                    setVehicleType={setVehicleType}
                    allAvailableVehicles={allAvailableVehicles}
                    setIsDocuments={setIsDocuments}
                  /> */}

                  <div className="flex justify-between">
                    <div className="mb-3 me-6 w-full">
                      <div className="input-container">
                        <input
                          required
                          className="w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          name="label"
                          type="text"
                          id="label"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.label}
                        />
                        <label
                          htmlFor="label"
                          className={`input-custom-label dark:text-white ${
                            values?.label ? "filled" : ""
                          }`}
                        >
                          Skill
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.label && touched.label ? errors.label : null}
                      </div>
                    </div>
                    {/* <div className="mb-3 ms-6 w-full"></div> */}
                  </div>

                  <div className="button-save-cancel mt-3 flex justify-end">
                    <Button
                      className=" cancel-button my-2 ms-1 sm:my-0"
                      onClick={() => {
                        // navigate("/admin/Skills")
                        window.history.back();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      className="save-button my-2 ms-1 bg-brand-500 dark:bg-brand-400 sm:my-0"
                    >
                      Save
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Card>
      )}
    </>
  );
};

export default SkillForm;
