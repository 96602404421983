import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../../../components/navbar";
import Loader from "../../../../components/loader/loader";
import Card from "../../../../components/card";
import avatar from "../../../../assets/svg/dummyProfile.svg";
import email from "../../../../assets/svg/email.svg";
import call from "../../../../assets/svg/call (2).svg";
import star from "../../../../assets/svg/Star.svg";
import bookmark from "../../../../assets/svg/bookmark.svg";
import img from "../../../../assets/images/react.jpg";
import "./userDetails.css";
import { getAllUserVideosApi, getUserDetailsApi } from "services/customAPI";
import Header from "components/common/TableHeader";
import { toaster } from "components/common/toaster";
import ReactApexChart from "react-apexcharts";

const ImageWithFallback: React.FC<{
  src: string;
  alt: string;
  fallbackSrc: string;
  className?: any;
}> = ({ src, alt, fallbackSrc, className }) => {
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement>) => {
    // This function is called when the original image fails to load.
    // Set the source (src) of the image to the fallback source.
    event.currentTarget.src = fallbackSrc;
  };
  return (
    <img src={src} alt={alt} onError={handleImageError} className={className} />
  );
};

const UserDetails = (props: any) => {
  const params = useParams();
  const [thumbnailURL, setThumbnailUrl] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [overFlow, setOverflow] = useState(false);
  const { sideBarUseState, setSideBarUseState } = props;
  const [state, setState] = useState({
    email: "",
    gender: "",
    number: "",
    profileURL: "",
    userName: "",
    status: "",
    totalAmount: "",
    history: [],
    watchHistory: [],
    totalTime: "",
    labels: [],
    series: [],
    // data: {} as chartData,
  });

  const getData = async (id: string) => {
    setIsLoading(true);
    try {
      const res: any = await getUserDetailsApi(id);

      // console.log("object res :>> ", res.data);
      setState((prev: any) => ({
        ...prev,
        email: res?.data?.UserDetails?.email,
        gender: res?.data?.UserDetails?.gender,
        number: res?.data?.UserDetails?.number,
        profileURL: res?.data?.UserDetails?.profile_url,
        userName: res?.data?.UserDetails?.userName,
        status: res?.data?.transactionData?.currentStatus,
        totalAmount: res?.data?.transactionData?.totalAmount,
        history: res?.data?.transactionData?.history,
        watchHistory: res?.data?.watchHistory,
        totalTime: res?.data?.totalTime,
      }));
      console.log("URL", res?.data?.watchHistory);
      setThumbnailUrl(await getThumbnails(res?.data?.watchHistory));
      top5(res?.data?.watchTime);
      // console.log("thumbnailURL", thumbnailURL);
      setIsLoading(false);
    } catch (error: any) {
      toaster.errorToast(error.response.data.message);
      //console.log("error :>> ", error.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  async function getThumbnails(Array: Array<any>) {
    return await Promise.all(
      Array.map(async (video) => {
        let Path;
        // console.log("Thumbnail_url", video?.thumbnail_url);
        if (video?.thumbnail_url) {
          Path = video?.thumbnail_url;
        } else {
          Path = "";
        }
        return { path: Path };
      })
    );
  }

  function convertStoHs(seconds: any) {
    let sec = Math.trunc(seconds);
    let hours = Math.floor(sec / 3600);
    let extraSeconds = (sec % 3600) / 3600;
    const result = hours + extraSeconds;
    return result.toFixed(2);
  }

  async function top5(watchTimeData: any) {
    // Sort the data by watchTime in descending order
    console.log("watchTimeData top5 called");
    watchTimeData.sort((a: any, b: any) => b.watchTime - a.watchTime);

    // Initialize arrays for the top 5 items and "Others"
    const top5Data = [];
    const othersData = [];

    // Iterate through the sorted data
    for (let i = 0; i < watchTimeData.length; i++) {
      if (i < 4) {
        // Add the top 5 items to top5Data
        top5Data.push(watchTimeData[i]);
      } else {
        // Aggregate the rest into "Others"
        const watchTime = watchTimeData[i].watchTime;
        if (othersData.length === 0) {
          othersData.push({
            _id: "Others",
            watchTime: watchTime,
          });
        } else {
          othersData[0].watchTime += watchTime;
        }
      }
    }

    // Create labels for the top 5 items and "Others"
    const labels = top5Data.map(
      (item) => `${item._id}: ${convertStoHs(item.watchTime)} Hr`
    );
    if (othersData.length > 0) {
      labels.push("Others");
    }

    // Create the series data for the chart
    const series = top5Data.map((item) => item.watchTime);
    if (othersData.length > 0) {
      series.push(othersData[0].watchTime);
    }
    setState((prevState) => ({
      ...prevState,
      labels: labels,
      series: series,
    }));
  }
  // Update the data object

  const data = {
    options: {
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "10px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
        },
      },
      labels: state.labels,
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              name: {
                // show: true,
                show: false,
              },
              total: {
                fontSize: "15px",
                show: true,
                showAlways: false,
                formatter: function () {
                  return `${convertStoHs(state.totalTime)} Hr`;
                },
              },
            },
          },
        },
      },
      stroke: {
        show: false,
        width: 0,
      },
      //   responsive: [
      //     {
      //       breakPoint: "375px",
      //       options: {
      //         chart: {
      //           width: "100%",
      //           height: "100%",
      //           type: "donut",
      //         },
      //       },
      //     },
      //   ],
      //   maintainAspectRatio: true,
    },
    series: state.series,
  };

  async function viewAllVideos(id: string) {
    // console.log("StateOverFlow", overFlow);
    setOverflow(overFlow ? false : true);
    if (!overFlow) {
      const res: any = await getAllUserVideosApi(id);
      console.log("AllVideosRes", res);
      setState((prevState) => ({
        ...prevState,
        watchHistory: res?.data?.watchHistory,
      }));
      setThumbnailUrl(await getThumbnails(res?.data?.watchHistory));
      return;
    }
  }

  React.useEffect(() => {
    if (params.id) {
      getData(params.id);
    }
  }, [params.id]);

  return (
    <>
      <Navbar
        flag={false}
        brandText="Ride details"
        sideBarUseState={sideBarUseState}
        setSideBarUseState={setSideBarUseState}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Header
            title="User Details"
            showAddCategoryButton={false}
            showSearch={false}
            showSelect={false}
          />
          <Card
            extra={
              "w-full pb-0 p-4 pt-0 pe-0 h-full mt-5 mb-5 grid grid-cols-2 gap-x-4"
            }
          >
            <div className="col-span-2">
              <div className="flex flex-row items-end pb-4">
                {state.profileURL ? (
                  <ImageWithFallback
                    src={state.profileURL}
                    alt={"profile image"}
                    fallbackSrc={avatar}
                    className="ml-8 mt-8 h-12 w-12 rounded-xl md:h-24 md:w-24"
                  />
                ) : (
                  <img
                    src={avatar}
                    className="ml-8 mt-8 h-12 w-12 md:h-24 md:w-24"
                  />
                )}

                <p className="ml-4 text-sm font-bold text-navy-700 dark:text-white md:text-xl">
                  {state.userName ? state.userName : "N/A"}
                </p>
                <p className="ml-2 text-xs font-bold text-gray-700 dark:text-white">
                  {"("} {state.gender ? state.gender : "N/A"}
                  {")"}
                </p>
              </div>
              <hr className="mr-4 lg:col-span-2 lg:w-1/2" />
            </div>
            <div className="col-span-2 mx-2 mt-4 h-44 lg:col-span-1 lg:h-72">
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row items-center">
                  <img src={email} width={15} height={15} />
                  <p className="ml-2 text-xs font-bold text-navy-700 dark:text-white sm:text-sm md:text-lg">
                    {state.email ? state.email : "N/A"}
                  </p>
                </div>
                <div className="flex flex-row items-center">
                  <img src={call} width={15} height={15} />
                  <p className="ml-2 text-xs font-bold text-navy-700 dark:text-white sm:text-sm md:text-lg">
                    {state.number ? state.number : "N/A"}
                  </p>
                </div>
              </div>
              <div className="h-full w-full">
                <ReactApexChart
                  options={data.options}
                  series={data.series}
                  type="donut"
                  height="88%"
                />
              </div>
            </div>
            <div
              className="col-span-2 mx-4 mb-4 rounded-xl bg-lightPrimary lg:col-span-1"
              style={{
                fontSize: "18px",
                fontWeight: "600",
                color: "#212121",
              }}
            >
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-col items-center pb-2 text-xs sm:text-sm md:flex-row md:text-lg">
                  <p className="mb-2 ml-4 mr-2 mt-4 font-bold text-navy-700 dark:text-white md:m-4">
                    Currrent Plan:
                  </p>
                  <p
                    className={
                      state.status
                        ? "onlineClass mb-2 ml-0 ml-4 text-xs font-bold text-navy-700 dark:text-white sm:text-sm md:m-4 md:text-lg "
                        : "offlineClass mb-2 ml-0 ml-4 text-xs font-bold text-navy-700 dark:text-white sm:text-sm md:m-4 md:text-lg "
                    }
                  >
                    {state.status ? "Active" : "Inactive"}
                  </p>
                </div>
                <div className="flex flex-col items-center text-xs sm:text-sm md:flex-row md:text-lg">
                  <p className="mb-2 mr-4 font-bold text-navy-700 dark:text-white md:m-4">
                    Total:
                  </p>
                  <p className="mr-4 font-bold text-navy-700 dark:text-white md:m-4 md:ml-0">
                    &#8377; {state.totalAmount}
                  </p>
                </div>
              </div>
              <hr className=" m-4 mt-0" />
              <div className="flex flex-row items-center">
                <p className="m-4 mb-2 mt-0 text-xs font-bold text-navy-700 dark:text-white sm:text-sm md:text-lg">
                  Transaction History:
                </p>
              </div>
              {/* map function */}
              {state.history.map((history) => (
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-row items-center">
                    <p className="m-2 ml-4 mr-2 text-xs font-bold text-navy-700 dark:text-white sm:text-sm md:text-lg">
                      {history.date.slice(0, 10)}
                      {/* October 16, 2023 */}
                    </p>
                  </div>
                  <div className="flex flex-row items-center">
                    <p
                      style={{
                        fontWeight: "700",
                        color: "#49308C",
                      }}
                      className="m-2 ml-0 mr-4 text-xs font-bold text-navy-700 dark:text-white sm:text-sm md:text-lg"
                    >
                      &#8377; {history.amount}
                      {/* &#8377; 555 */}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <hr className="col-span-2 mb-4 mr-4" />
            <div className="col-span-2">
              <div className="flex flex-row items-center justify-between">
                <p className="mb-5 ml-4 text-xl font-bold text-navy-700 dark:text-white">
                  Watch History
                </p>
                <div className="mr-3">
                  <button
                    className=" flex items-center rounded-md bg-brand-500 px-3 py-2 font-bold text-white dark:bg-brand-400"
                    type="submit"
                    onClick={() => viewAllVideos(params.id)}
                  >
                    {!overFlow ? "View All" : "Close"}
                  </button>
                </div>
              </div>
              <div
                className={
                  overFlow
                    ? "mb-4 xl:flex xl:flex-row xl:overflow-x-auto"
                    : "mb-4 xl:flex xl:flex-row"
                }
              >
                {/* map videos */}
                <div className="grid grid-cols-2 items-center md:grid-cols-3 lg:grid-cols-4 xl:flex">
                  {!overFlow
                    ? state.watchHistory
                        .slice(0, 5)
                        .map((watchHistory, index) => (
                          <div className="col-span-1 m-3 flex h-44 w-[110px] flex-col rounded-xl border-2 border-solid border-navy-700 md:h-52 md:w-[200px] xl:w-[180px]">
                            <div className="relative h-1/2 truncate rounded-t-xl">
                              {thumbnailURL[index] ? (
                                <div
                                  style={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <ImageWithFallback
                                    src={thumbnailURL[index].path}
                                    alt={"video image"}
                                    fallbackSrc={img}
                                    className="h-full w-full"
                                  />
                                </div>
                              ) : (
                                <img
                                  src={img}
                                  className="w-full rounded-t-xl"
                                />
                              )}
                              {/* <img
                              width="250px"
                              src={img}
                              alt="image"
                              className="rounded-t-xl"
                            /> */}
                              <p className="absolute bottom-0 right-0 rounded-l-md bg-[#49308C] p-2 text-end text-[10px] font-bold text-white">
                                {watchHistory.duration} min
                              </p>
                            </div>
                            {/* getUserImage(res?.data?.UserDetails?.profile_url); */}
                            <div className="m-2 flex flex-row items-start justify-between rounded-b-xl ">
                              <p className=" w-2/3 text-xs font-bold text-navy-700 dark:text-white">
                                {watchHistory.title}
                              </p>
                              <img
                                width="12"
                                src={bookmark}
                                alt="image"
                                className="mt-[3px]"
                              />
                            </div>
                            <div className="relative m-2 flex flex-grow flex-row items-end justify-between rounded-b-xl">
                              <p className=" w-3/4 text-xs font-bold text-navy-700 dark:text-white">
                                {watchHistory.authorName}
                              </p>
                              <div className="flex flex-row items-center text-xs">
                                <img src={star} alt="star" width="11px" />
                                {watchHistory.rating}
                              </div>
                            </div>
                          </div>
                        ))
                    : state.watchHistory.map((watchHistory, index) => (
                        <div className="col-span-1 m-3 flex h-44 w-[110px] flex-col rounded-xl border-2 border-solid border-navy-700 md:h-52 md:w-[200px] xl:w-[180px]">
                          <div className="relative h-1/2 truncate rounded-t-xl">
                            {thumbnailURL[index] ? (
                              <div
                                style={{
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <ImageWithFallback
                                  src={thumbnailURL[index].path}
                                  alt={"video image"}
                                  fallbackSrc={img}
                                  className="h-full w-full"
                                />
                              </div>
                            ) : (
                              <img src={img} className="w-full rounded-t-xl" />
                            )}
                            {/* <img
                        width="250px"
                        src={img}
                        alt="image"
                        className="rounded-t-xl"
                      /> */}
                            <p className="absolute bottom-0 right-0 rounded-l-md bg-[#49308C] p-2 text-end text-[10px] font-bold text-white">
                              {watchHistory.duration} min
                            </p>
                          </div>
                          {/* getUserImage(res?.data?.UserDetails?.profile_url); */}
                          <div className="m-2 flex flex-row items-start justify-between rounded-b-xl ">
                            <p className=" w-2/3 text-xs font-bold text-navy-700 dark:text-white">
                              {watchHistory.title}
                            </p>
                            <img
                              width="12"
                              src={bookmark}
                              alt="image"
                              className="mt-[3px]"
                            />
                          </div>
                          <div className="relative m-2 flex flex-grow flex-row items-end justify-between rounded-b-xl">
                            <p className=" w-3/4 text-xs font-bold text-navy-700 dark:text-white">
                              {watchHistory.authorName}
                            </p>
                            <div className="flex flex-row items-center text-xs">
                              <img src={star} alt="star" width="11px" />
                              {watchHistory.rating}
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </Card>
        </>
      )}
    </>
  );
};

export default UserDetails;
