/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import routesAdmin from "routes1";
import routesSuperAdmin from "routes";
import logo from "../../assets/svg/logosidebar.svg";
import { useEffect } from "react";

const Sidebar = (props: any) => {
  const { sideBarUseState, setSideBarUseState } = props;
  const role = localStorage.getItem("role");
  const routes =
    role === "superAdmin"
      ? routesSuperAdmin
      : role === "admin"
      ? routesAdmin
      : null;

  const sidebarFlag =
    window.innerWidth > 1200 && sideBarUseState ? true : sideBarUseState;

  useEffect(() => {
    if (window.innerWidth > 1200) {
      setSideBarUseState(true);
    }
  }, [window.innerWidth]);

  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        sidebarFlag ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className={` absolute right-4 top-4 block cursor-pointer xl:hidden`}
        onClick={() => setSideBarUseState(false)}
      >
        <HiX />
      </span>

      <div className={`mx-[44px] mt-[50px] flex items-center`}>
        <div className="ml-1 mt-1 h-2.5 cursor-pointer">
          {/* Horizon <span className="font-medium">FREE</span> */}
          <a href="/">
            <img src={logo} />
          </a>
        </div>
      </div>
      <div className="mb-7 mt-[58px] h-px " />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <Links routes={routes} />
      </ul>

      {/* Free Horizon Card */}
      {/* <div className="flex justify-center">
        <SidebarCard />
      </div> */}

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
