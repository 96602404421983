import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAllVideos,
  deleteVideoHandleApi,
  getS3SignUrlApi,
} from "../../../services/customAPI";
import ReactPaginate from "react-paginate";
import Loader from "components/loader/loader";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button, ChakraProvider } from "@chakra-ui/react";
import deleteIcon from "../../../assets/svg/deleteIcon.svg";
import ButtonEdit from "../../../assets/svg/ButtonEdit.svg";
import { createColumnHelper } from "@tanstack/react-table";
import "./videoslist.css";
import Navbar from "../../../components/navbar";
import { useDisclosure } from "@chakra-ui/hooks";
import { toaster } from "components/common/toaster";
import Header from "components/common/TableHeader";
import Table from "components/common/TableBody";
// import LimitSelect from "components/common/paginationLimitSelect";

type RowObj = {
  videos: customFieldType1;
  duration: string;
  courseTag: string;
  date: string;
  action: customFieldType2;
};

type customFieldType1 = {
  title: string;
  path: string;
};

type customFieldType2 = {
  id: String;
};

const columnHelper = createColumnHelper<RowObj>();

const Videos = (props: any) => {
  const { sideBarUseState, setSideBarUseState } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const currentPage = useRef<number>();
  const navigate = useNavigate();
  // const [limit, setLimit] = useState(10);
  const [selectedItem, setSelectedItem] = useState(null);
  // const [modalState, setModalState] = useState(true);
  // const [visibleModal, setVisibleModal] = useState(false);
  // const [pageCount, setPageCount] = useState(1);
  // const [searchText, setSearchText] = useState("");
  const [pageItemStartNumber, setPageItemStartNumber] = useState<any>(0);
  const [pageItemEndNumber, setPageItemEndNumber] = useState<any>(0);
  const firstRender = useRef(true);
  const [state, setState] = useState({
    pageCount: 1,
    searchText: "",
    // tableData: [],
    limit: 10,
    noData: false,
  });

  const setPageItemRange = (currPageNumber: number, maxItemRange: number) => {
    let startNumber = currPageNumber * state.limit - state.limit + 1;
    if (startNumber < 0) {
      startNumber = 0;
    }
    setPageItemStartNumber(
      startNumber.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
    );

    const endNumber = currPageNumber * state.limit;
    //console.log("endNumber :>> ", endNumber);
    //console.log("maxItemRange :>> ", maxItemRange);
    setPageItemEndNumber(
      Math.min(endNumber, maxItemRange).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
    );
  };

  const columns = [
    columnHelper.accessor("videos", {
      id: "videos",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          videos
        </p>
      ),
      cell: (info) => (
        <p
          className="text-sm font-bold text-navy-700"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div
            style={{
              width: "7rem",
              height: "5rem",
              borderRadius: "4px",
              display: "inline-block",
              marginRight: "6px",
              alignItems: "center",
            }}
          >
            <img
              src={info.getValue()?.path}
              style={{
                objectFit: "fill",
                height: "100%",
                width: "auto",
                borderRadius: "4px",
              }}
              alt="Thumbnail"
            />
          </div>
          <span className="dark:text-white">{info.getValue()?.title}</span>
        </p>
      ),
    }),
    columnHelper.accessor("duration", {
      id: "duration",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Duration
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("courseTag", {
      id: "courseTag",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Course Tag
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("date", {
      id: "date",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Date</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("action", {
      id: "action",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Action
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <div>
            <img
              src={ButtonEdit}
              className="me-2 cursor-pointer"
              onClick={() =>
                navigate(`/admin/Videos/VideoForm/${info.getValue().id}`)
              }
            />
          </div>

          <div>
            <img
              src={deleteIcon}
              className="cursor-pointer"
              onClick={() => handleClickForDeleteModal(info.row.original)}
            />
          </div>
        </div>
      ),
    }),
  ]; // eslint-disable-next-line

  function handlePageClick(e: any) {
    setIsLoading(true);
    //console.log(e);
    currentPage.current = e.selected + 1;
    getVideoListing(state.limit);
    //    if (searchText !== "") {
    //      searchDriverFunction();
    //    } else {
    //      getPaginatedDriverData();
    //    }

    setIsLoading(false);
  }

  async function getS3SignUrl(key: string, contentType: string, type: string) {
    const headers = { "Content-Type": "application/json" };
    const response: any = await getS3SignUrlApi(
      {
        key,
        contentType,
        type,
      },
      { headers }
    );
    return response;
  }

  function convertUtcToIst(utcTimeStr: string) {
    try {
      if (utcTimeStr) {
        const utcDate = new Date(utcTimeStr);
        const istOffsetMinutes = 330; // IST offset in minutes (UTC+5:30)

        const istTime = new Date(
          utcDate.getTime() + istOffsetMinutes * 60 * 1000
        );
        return istTime.toISOString(); // Return in ISO format
      }
    } catch (error: any) {
      toaster.errorToast(error);
      console.error("Invalid UTC time value:", utcTimeStr);
      return null; // Handle the error gracefully, return null, or throw a custom error
    }
  }

  async function convertToUsableArray(Array: Array<any>) {
    return await Promise.all(
      Array.map(async (video) => {
        let Path;
        // //console.log("Thumbnail_url", video?.courseDetails[0]?.thumbnail_url);
        if (video?.courseDetails[0]?.thumbnail_url) {
          Path = video?.courseDetails[0]?.thumbnail_url;
          // //console.log("objectpath :>> ", Path);
        } else {
          Path = "";
        }

        return {
          videos: {
            title: video?.title,
            path: Path,
          },
          duration: video?.duration,
          courseTag: video?.courseDetails[0]?.classType,
          date: video?.createdAt
            ? convertUtcToIst(video?.createdAt).substring(0, 10)
            : "N/A",
          action: {
            id: video?._id,
          },
        };
      })
    );
  }

  const handleClickForDeleteModal = (data: any) => {
    //console.log("data delete :>> ", data);
    setIsLoading(true);
    setSelectedItem(data);
    onOpen();
    setIsLoading(false);
  };

  const deleteHandle = async (info: any) => {
    setIsLoading(true);
    onClose();
    //console.log("deletehandler", info);
    try {
      const response: any = await deleteVideoHandleApi(info);
      //console.log("delete response", response);
      if (response) {
        toaster.successToast("Video deleted successfully");
        currentPage.current = 1;
        getVideoListing(state.limit);
      } else {
        toaster.errorToast("Something went wrong");
      }
      setIsLoading(false);
    } catch (error: any) {
      toaster.errorToast(error);
      //console.log("error :>> ", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchSubmit = async (e: any) => {
    // e.preventDefault();
    if (state.searchText.trim() == "") {
      return;
    }
    currentPage.current = 1;
    getVideoListing(state.limit);
  };

  const getVideoListing = async (limit: number) => {
    setIsLoading(true);
    try {
      const response: any = await getAllVideos({
        page: currentPage.current,
        limit: limit,
        text: state.searchText.trim(),
      });
      if (response === 0) {
        console.log("Response", response);
        setState((prev: any) => ({
          ...prev,
          noData: true,
          pageCount: 0,
        }));
        setPageItemRange(0, 0);
      } else {
        setState((prev: any) => ({
          ...prev,
          pageCount: response.pageCount,
          searchText: state.searchText,
          noData: false,
          // tableData: convertToUsableArray(response.result),
        }));

        // setPageCount(response.pageCount);
        // setSearchText(searchText);
        setTableData(await convertToUsableArray(response.result[0].video));
        setPageItemRange(currentPage.current, response.totalVideos);
      }
    } catch (error: any) {
      toaster.errorToast(error.response.data.message);
      //console.log("error :>> ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaginationLimit = (e: any) => {
    setState((prevState) => ({
      ...prevState,
      limit: e.value,
    }));
    currentPage.current = 1;
    getVideoListing(e.value);
    // console.log("Limit", limit);
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      currentPage.current = 1;
      getVideoListing(state.limit);
    } else {
      if (state.searchText == "") {
        currentPage.current = 1;
        getVideoListing(state.limit);
      }
    }
  }, [state.searchText, state.limit]);

  // useEffect(() => {
  //   currentPage.current = 1;
  //   getVideoListing();
  // }, []);

  return (
    <div>
      <Navbar
        flag={true}
        brandText="Videos"
        sideBarUseState={sideBarUseState}
        setSideBarUseState={setSideBarUseState}
        // handleSearch={(e: React.SyntheticEvent<EventTarget>) =>
        //   handleSearchSubmit(e)
        // }
        // setSearchText={setSearchText}
      />
      {/* {!noData ? (
        <Card extra={" m-4 w-full p-4 h-full"}>
          <div className="m-4 text-center">
            <h2 style={{ fontSize: "30px" }}>No Results !</h2>
          </div>
        </Card>
      ) : ( */}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <>
            <div className="mt-4">
              <Header
                title="Videos"
                setSearchText={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    searchText: value,
                  }))
                }
                handleSearchSubmit={handleSearchSubmit}
                searchText={state.searchText}
                showSelect={false}
                navigation="/admin/Videos/VideoForm"
              />
              <Table
                tableData={tableData}
                columns={columns}
                noData={state.noData}
              />
              <div
                className="mx-2 "
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color: "#5E6278",
                  marginTop: "10px",
                }}
              >
                <h5>
                  {pageItemStartNumber} - {pageItemEndNumber}
                </h5>
                {/* <LimitSelect
                  limit={state.limit}
                  handlePaginationLimit={handlePaginationLimit}
                /> */}
                {/* <Select
                  isSearchable={false}
                  // className="w-400"
                  className="custom-select"
                  options={paginationLimit}
                  onChange={(e: any) => {
                    handlePaginationLimit(e);
                  }}
                  value={paginationLimit.filter(function (option: any) {
                    return option.value === limit;
                  })}
                  name="limit"
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided: any) => ({
                      ...provided,
                      zIndex: 9999,
                      position: "absolute",
                    }),
                    option: (provided: any, state: any) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#f2f3f7" : "white", // Change the background color here
                      color: "black", // Change the text color here
                      "&:hover": {
                        backgroundColor: "#f2f3f7", // Change the background color on hover
                      },
                    }),
                    // control: (base: any) => ({
                    //   ...base,
                    //   flexDirection: 'row-reverse',
                    // })
                  }}
                  components={{
                    DropdownIndicator: arrowdown,
                    IndicatorSeparator: () => null,
                    ValueContainer: filter,
                  }}
                /> */}
                <div>
                  <ReactPaginate
                    breakLabel=" .  .  . "
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={state.pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName="pagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="previous-page-btn"
                    previousLinkClassName="page-link"
                    nextClassName="next-page-btn"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    forcePage={currentPage.current - 1}
                  />
                </div>
              </div>
            </div>
          </>
          <>
            {isOpen && (
              <ChakraProvider>
                <Modal
                  isCentered={true}
                  isOpen={isOpen}
                  onClose={onClose}
                  size="xs"
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader></ModalHeader>
                    <div className="mb-2 flex justify-center">
                      <img src={deleteIcon} />
                    </div>
                    <ModalBody className="text-center">
                      Are you sure you want to Delete? <br />
                      {'"' + selectedItem?.videos?.title + '"'}
                    </ModalBody>

                    <div className="mt-3 flex justify-center">
                      <Button
                        className="cancel-delete-modal-button mx-2"
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="delete-modal-button mx-2"
                        onClick={() => deleteHandle(selectedItem.action.id)}
                      >
                        Delete
                      </Button>
                    </div>
                    <ModalFooter></ModalFooter>
                  </ModalContent>
                </Modal>
              </ChakraProvider>
            )}
          </>
        </>
      )}
    </div>
  );
};

export default Videos;
