import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAllSkills,
  deleteSkillHandleApi,
} from "../../../services/customAPI";
import ReactPaginate from "react-paginate";
import Loader from "components/loader/loader";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button, ChakraProvider } from "@chakra-ui/react";
import deleteIcon from "../../../assets/svg/deleteIcon.svg";
import ButtonEdit from "../../../assets/svg/ButtonEdit.svg";
import { createColumnHelper } from "@tanstack/react-table";
import "./skilllist.css";
import Navbar from "../../../components/navbar";
import { useDisclosure } from "@chakra-ui/hooks";
import { toaster } from "components/common/toaster";
import Header from "components/common/TableHeader";
import Table from "components/common/TableBody";
// import LimitSelect from "components/common/paginationLimitSelect";

type RowObj = {
  label: string;
  action: customFieldType2;
};

type customFieldType2 = {
  id: String;
};

const columnHelper = createColumnHelper<RowObj>();

const Skills = (props: any) => {
  const { sideBarUseState, setSideBarUseState } = props;
  const [isLoading, setIsLoading] = useState(false);
  // const [tableData, setTableData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const currentPage = useRef<number>();
  const navigate = useNavigate();
  // const [limit, setLimit] = useState(10);
  const [selectedItem, setSelectedItem] = useState(null);
  // const [modalState, setModalState] = useState(true);
  // const [visibleModal, setVisibleModal] = useState(false);
  // const [pageCount, setPageCount] = useState(1);
  // const [searchText, setSearchText] = useState("");
  const [pageItemStartNumber, setPageItemStartNumber] = useState<any>(0);
  const [pageItemEndNumber, setPageItemEndNumber] = useState<any>(0);
  const [noData, setNoData] = useState(true);
  const firstRender = useRef(true);
  const [state, setState] = useState({
    pageCount: 1,
    searchText: "",
    tableData: [],
    limit: 10,
    noData: false,
  });

  const columns = [
    columnHelper.accessor("label", {
      id: "label",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Skill</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("action", {
      id: "action",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Action
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center justify-end">
          <div>
            <img
              src={ButtonEdit}
              className="me-2 cursor-pointer"
              onClick={() =>
                navigate(`/admin/Skills/SkillForm/${info.getValue().id}`)
              }
            />
          </div>

          <div>
            <img
              src={deleteIcon}
              className="cursor-pointer"
              onClick={() => handleClickForDeleteModal(info.row.original)}
            />
          </div>
        </div>
      ),
    }),
  ]; // eslint-disable-next-line

  const setPageItemRange = (currPageNumber: number, maxItemRange: number) => {
    let startNumber = currPageNumber * state.limit - state.limit + 1;
    if (startNumber < 0) {
      startNumber = 0;
    }
    setPageItemStartNumber(
      startNumber.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
    );

    const endNumber = currPageNumber * state.limit;
    //console.log("endNumber :>> ", endNumber);
    //console.log("maxItemRange :>> ", maxItemRange);
    setPageItemEndNumber(
      Math.min(endNumber, maxItemRange).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })
    );
  };

  function handlePageClick(e: any) {
    setIsLoading(true);
    //console.log(e);
    currentPage.current = e.selected + 1;
    getSkillListing(state.limit);
    //    if (searchText !== "") {
    //      searchDriverFunction();
    //    } else {
    //      getPaginatedDriverData();
    //    }

    setIsLoading(false);
  }

  function convertToUsableArray(Array: Array<any>) {
    const res = Array.map((Skill) => {
      return {
        label: Skill?.label,
        action: {
          id: Skill?._id,
        },
      };
    });

    return res;
  }

  const handleClickForDeleteModal = (data: any) => {
    //console.log("data delete :>> ", data);
    setIsLoading(true);
    setSelectedItem(data);
    onOpen();
    setIsLoading(false);
  };

  const deleteHandle = async (info: any) => {
    setIsLoading(true);
    onClose();
    //console.log("deletehandler", info);
    try {
      const response: any = await deleteSkillHandleApi(info);
      //console.log("delete response", response);
      if (response) {
        toaster.successToast("Skill deleted successfully");
        currentPage.current = 1;
        getSkillListing(state.limit);
      } else {
        toaster.errorToast("Something went wrong");
      }
      setIsLoading(false);
    } catch (error: any) {
      toaster.errorToast(error.response.data.message);
      //console.log("error :>> ", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchSubmit = async (e: any) => {
    // e.preventDefault();
    if (state.searchText.trim() == "") {
      return;
    }
    currentPage.current = 1;
    getSkillListing(state.limit);
  };

  const handlePaginationLimit = (e: any) => {
    setState((prevState) => ({
      ...prevState,
      limit: e.value,
    }));
    currentPage.current = 1;
    getSkillListing(e.value);
    // console.log("Limit", limit);
  };

  const getSkillListing = async (limit: number) => {
    setIsLoading(true);
    try {
      const response: any = await getAllSkills({
        page: currentPage.current,
        limit: limit,
        text: state.searchText.trim(),
      });

      if (response === 0) {
        console.log("Response", response);
        setState((prev: any) => ({
          ...prev,
          noData: true,
          pageCount: 0,
        }));
        setPageItemRange(0, 0);
      } else {
        setState((prev: any) => ({
          ...prev,
          pageCount: response.pageCount,
          searchText: state.searchText,
          tableData: convertToUsableArray(response?.result[0].skill),
          noData: false,
        }));
        // setPageCount(response.pageCount);
        // setSearchText(searchText);
        // setTableData(convertToUsableArray(response.result));
        setPageItemRange(currentPage.current, response.totalSkills);
      }
    } catch (error: any) {
      toaster.errorToast(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      currentPage.current = 1;
      getSkillListing(state.limit);
    } else {
      if (state.searchText == "") {
        currentPage.current = 1;
        getSkillListing(state.limit);
      }
    }
  }, [state.searchText, state.limit]);

  // useEffect(() => {
  //   currentPage.current = 1;
  //   getSkillListing();
  // }, []);

  return (
    <div>
      <Navbar
        flag={true}
        brandText="Skills"
        sideBarUseState={sideBarUseState}
        setSideBarUseState={setSideBarUseState}
        // handleSearch={(e: React.SyntheticEvent<EventTarget>) =>
        //   handleSearchSubmit(e)
        // }
        // setSearchText={setSearchText}
      />
      {/* {!noData ? (
        <Card extra={" m-4 w-full p-4 h-full"}>
          <div className="m-4 text-center">
            <h2 style={{ fontSize: "30px" }}>No Results !</h2>
          </div>
        </Card>
      ) : ( */}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <>
            <div className="mt-4">
              <Header
                title="Skills"
                setSearchText={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    searchText: value,
                  }))
                }
                showSelect={false}
                handleSearchSubmit={handleSearchSubmit}
                searchText={state.searchText}
                navigation="/admin/Skills/SkillForm"
              />
              <Table
                tableData={state.tableData}
                columns={columns}
                header={false}
                rowGap={true}
                noData={state.noData}
              />
              <div
                className="mx-2 "
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color: "#5E6278",
                }}
              >
                <h5>
                  {pageItemStartNumber} - {pageItemEndNumber}
                </h5>
                {/* <LimitSelect
                  limit={state.limit}
                  handlePaginationLimit={handlePaginationLimit}
                /> */}
                {/* <Select
                  isSearchable={false}
                  // className="w-400"
                  className="custom-select"
                  options={paginationLimit}
                  onChange={(e: any) => {
                    handlePaginationLimit(e);
                  }}
                  value={paginationLimit.filter(function (option: any) {
                    return option.value === state.limit;
                  })}
                  name="limit"
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided: any) => ({
                      ...provided,
                      zIndex: 9999,
                      position: "absolute",
                    }),
                    option: (provided: any, state: any) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#f2f3f7" : "white", // Change the background color here
                      color: "black", // Change the text color here
                      "&:hover": {
                        backgroundColor: "#f2f3f7", // Change the background color on hover
                      },
                    }),
                    // control: (base: any) => ({
                    //   ...base,
                    //   flexDirection: 'row-reverse',
                    // })
                  }}
                  components={{
                    DropdownIndicator: arrowdown,
                    IndicatorSeparator: () => null,
                    ValueContainer: filter,
                  }}
                /> */}
                <div style={{ marginTop: "1rem" }}>
                  <ReactPaginate
                    breakLabel=" .  .  . "
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={state.pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName="pagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="previous-page-btn"
                    previousLinkClassName="page-link"
                    nextClassName="next-page-btn"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    forcePage={currentPage.current - 1}
                  />
                </div>
              </div>
            </div>
          </>
          <>
            {isOpen && (
              <ChakraProvider>
                <Modal
                  isCentered={true}
                  isOpen={isOpen}
                  onClose={onClose}
                  size="xs"
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader></ModalHeader>
                    <div className="mb-2 flex justify-center">
                      <img src={deleteIcon} />
                    </div>
                    <ModalBody className="text-center">
                      Are you sure you want to Delete? <br />
                      {'"' + selectedItem.label + '"'}
                    </ModalBody>

                    <div className="mt-3 flex justify-center">
                      <Button
                        className="cancel-delete-modal-button mx-2"
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="delete-modal-button mx-2"
                        onClick={() => deleteHandle(selectedItem.action.id)}
                      >
                        Delete
                      </Button>
                    </div>
                    <ModalFooter></ModalFooter>
                  </ModalContent>
                </Modal>
              </ChakraProvider>
            )}
          </>
        </>
      )}
    </div>
  );
};

export default Skills;
