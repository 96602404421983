import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";

import CustomRoutes from "./App";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root")); 

root.render(
  <BrowserRouter>
    <CustomRoutes />
    <ToastContainer />
  </BrowserRouter>
);
