import axios from "axios";

const customAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

customAxios.interceptors.request.use(
  function (request) {
    // Do something before request is sent
    // request.headers.Authorization = `Bearer ${store.getState().auth.token}`;
    // console.log("object request:>> ", request);
    const token = localStorage.getItem("token");
    if (!token && request.url !== "/admin/login") {
      // console.log("token not found at interceptors :>> ");
      if (window) {
        window.location.href = "/";
      }
    }
    request.headers.Authorization = `Bearer ${token}`;
    return request;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

customAxios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log("Interceptor Data :>> ", response.headers["x-access-token"]);
    // if (response.headers["x-access-token"]) {
    //   localStorage.setItem("token", response.headers["x-access-token"]);
    // }
    return response.data;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // //console.log('Interceptor Error :>> ', error)
    if (error.response.status === 403 || error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      if (window) {
        window.location.href = "/";
      }
    }
    return Promise.reject(error);
  }
);

export default customAxios;
